import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamsLoginUser, ResponseUserLogin } from '@mzic/mzic-interfaces';
import { API_URL, ConfirmationTypeEnum, MeanTypeEnum } from '@mzic/mzic-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MzicAuthUserService {
  constructor(private readonly _http: HttpClient) {}

  public authenticateUser(
    params: ParamsLoginUser,
  ): Observable<ResponseUserLogin> {
    return this._http.post<ResponseUserLogin>(
      API_URL.USER_ARTIST_LOGIN,
      params,
    );
  }

  public logOutUser(): Observable<void> {
    return this._http.post<void>(API_URL.USER_ARTIST_SIGN_OUT, {});
  }

  public confirmCode(
    payload: {
      userId: number;
      code: string;
      username: string;
      email: string;
    },
    typeConfirmCode: {
      meanType: MeanTypeEnum;
      confirmationType: ConfirmationTypeEnum;
    },
  ): Observable<ResponseUserLogin> {
    const body = {
      user: {
        username: payload.username,
        email: payload.email,
        userId: payload.userId,
      },
      code: payload.code,
    };

    return this._http.post<ResponseUserLogin>(
      API_URL.CONFIRM_CODE(
        typeConfirmCode.meanType,
        typeConfirmCode.confirmationType,
      ),
      body,
    );
  }

  public resendVerificationCode(
    payload: {
      email: string;
      username: string;
      userId: number;
    },
    meanType: MeanTypeEnum,
    confirmationType: ConfirmationTypeEnum,
  ): Observable<void> {
    const body = {
      user: {
        username: payload.username,
        email: payload.email,
        userId: payload.userId,
      },
    };

    return this._http.post<void>(
      API_URL.RESEND_CONFIRM_CODE(meanType, confirmationType),
      body,
    );
  }

  public resendVerificationCodeWithMetadata(
    payload: {
      email: string;
      username: string;
      userId: number;
    },
    meanType: MeanTypeEnum,
    confirmationType: ConfirmationTypeEnum,
    metadata: {
      newPhoneNumber: string;
      newEmail: string;
    },
  ): Observable<void> {
    const body = {
      user: {
        username: payload.username,
        email: payload.email,
        userId: payload.userId,
      },
      data: metadata,
    };

    return this._http.post<void>(
      API_URL.RESEND_CONFIRM_CODE(meanType, confirmationType),
      body,
    );
  }
}
