import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WalletTeamResponse } from '@mzic/mzic-interfaces';
import { API_URL } from '@mzic/mzic-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MzicTeamResourceService {
  constructor(private readonly _http: HttpClient) {}

  public get(page?: number, size?: number): Observable<WalletTeamResponse> {
    let params = new HttpParams();

    if (page !== undefined) {
      params = params.append('page', page.toString());
    }

    if (size !== undefined) {
      params = params.append('size', size.toString());
    }

    return this._http.get<WalletTeamResponse>(API_URL.TEAMS, { params });
  }
}
