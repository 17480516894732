<h1>Menu Lateral Flutuante</h1>

<mzic-side-floating-menu [options]="options" sectionClassName="block-section" />

<div class="components-container">
  <div
    id="aaa"
    class="block-section"
    style="
      display: flex;
      width: 100%;
      height: 600px;
      background-color: rgba(255, 255, 0, 0.1);
      justify-content: center;
      align-items: center;
    ">
    AAA Content
  </div>
  <div
    id="bbb"
    class="block-section"
    style="
      display: flex;
      width: 100%;
      height: 600px;
      background-color: rgba(255, 0, 255, 0.1);
      justify-content: center;
      align-items: center;
    ">
    BBB Content
  </div>
  <div
    id="ccc"
    class="block-section"
    style="
      display: flex;
      width: 100%;
      height: 600px;
      background-color: rgba(0, 0, 255, 0.1);
      justify-content: center;
      align-items: center;
    ">
    CCC Content
  </div>
  <div
    id="ddd"
    class="block-section"
    style="
      display: flex;
      width: 100%;
      height: 600px;
      background-color: rgba(255, 0, 0, 0.1);
      justify-content: center;
      align-items: center;
    ">
    DDD Content
  </div>
</div>
