export interface AssetsAvailable {
  release: AssetsAvailableRelease;
  track: AssetsAvailableTrack;
  type?: 'release' | 'track';
}

export interface AssetsAvailableRelease {
  id: number;
  hash: string;
  title: string;
  titleVersion: string;
  artistNames: string;
  hasReleased: boolean;
  upc: string;
  releaseDate: string;
  releaseTypeEnum: string;
  label: AssetsAvailableLabel;
  tracks: AssetsAvailableTrack[];
  eligibleForSplit: boolean;
  checked: boolean;
  childrenOpened: boolean;
}

export interface AssetsAvailableTrack {
  id: number;
  releaseId?: number;
  hash: string;
  title: string;
  titleVersion: string;
  artistNames: string;
  eligibleForSplit: AssetsAvailableTrackEligibleForSplit;
  isrc: string;
  label: AssetsAvailableLabel;
  checked?: boolean;
}

export interface AssetsAvailableTrackEligibleForSplit {
  eligible: boolean;
  reason: string;
  ruleNotEligible: RuleNotEligible;
}

export interface RuleNotEligible {
  countryList: CountryList[];
  dspList: DspList[];
}

export interface CountryList {
  id: number;
  name: string;
  nameInPortuguese: string;
  flagUrl: string;
  isoAlphaTwo: string;
  isoAlphaThree: string;
  isoCode: string;
  currencyType: string;
}

export interface DspList {
  name: string;
  distributorStoreId: number;
}

export interface AssetsAvailableLabel {
  id: string;
  imageId: string;
  name: string;
  description: string;
}
