import {
  ComponentRef,
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CalendarComponent } from '../../calendar.component';
import { CalendarOutput } from '../../model/calendar-output.model';

@Directive({
  selector: '[mzicCalendarPicker]',
  standalone: true,
})
export class CalendarPickerDirective implements OnInit, OnDestroy {
  public multidateSelect = input<boolean>(false);
  public quickSelectionOptions = input<boolean>(false);
  public calendarFormControl = input.required<FormControl>();
  public calendarPosition = input<'top' | 'bottom'>('bottom');
  public excludeWeekendsAndHolidays = input<boolean>(false);

  private _renderer2 = inject(Renderer2);
  private _elementRef = inject(ElementRef);
  private _viewContainerRef = inject(ViewContainerRef);
  private _componentRef!: ComponentRef<CalendarComponent>;

  private _currentLenghtOfInput = 0;
  private _maxLengthForSingleDateSelect = 10; // Número máximo de caracteres no campo de data para seleção de data única.
  private _maxLengthForMultiDateSelect = 23; // Número máximo de caracteres no campo de data para seleção de multi data.

  private _destroyRef = inject(DestroyRef);

  @HostListener('document:click', ['$event']) onClick(
    eventClick: PointerEvent,
  ): void {
    if (this._elementRef.nativeElement === eventClick.target) {
      if (!this._componentRef) {
        this._createCalendarComponent();
        this._openCalendar();
        return;
      }

      this._openCalendar();
    } else if (this._componentRef) {
      const pageX = eventClick.clientX;
      const pageY = eventClick.clientY;
      const { height, top, left, width } =
        this._componentRef.location.nativeElement.getBoundingClientRect();

      // Verifica posição Y do clique e fecha caso clique fora
      if (!(pageY > top && pageY < top + height)) {
        this._closeCalendar();
      }

      // Verifica posição X do clique e fecha caso clique fora
      if (!(pageX > left && pageX < left + width)) {
        this._closeCalendar();
      }
    }
  }

  ngOnInit() {
    this._setMaxLengthOnElementRef();

    this.calendarFormControl()
      .valueChanges.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => {
        if (!value) {
          if (this._componentRef) {
            this._componentRef.instance.clearDateSelected();
          }
        }

        this._handleInputText(value);
      });
  }

  private _setMaxLengthOnElementRef() {
    if (this.multidateSelect()) {
      this._renderer2.setAttribute(
        this._elementRef.nativeElement,
        'maxLength',
        this._maxLengthForMultiDateSelect.toString(),
      );
    } else {
      this._renderer2.setAttribute(
        this._elementRef.nativeElement,
        'maxLength',
        this._maxLengthForSingleDateSelect.toString(),
      );
    }
  }

  private _handleInputText(value: string): void {
    // Permite apagar a data no campo input sem adicionar infinitamente as '/'.
    if (this._currentLenghtOfInput >= value?.length) {
      this._currentLenghtOfInput = value.length;
      return;
    }

    // Se não for número, a letra digitada é automáticamente apagada do input.
    if (this._shouldExcludeLetters(value)) return;

    // Adiciona a máscara de data quando a seleção for entre duas datas.
    this._setMaskOnInput(value);

    // Atualiza o atual número de dígitos no input
    this._currentLenghtOfInput = this.calendarFormControl().value?.length;

    this._updateCalendar(value);
  }

  private _setMaskOnInput(value: string): void {
    switch (value?.length) {
      case 2: // Depois de 2 caracteres adiciona '/'.
        this.calendarFormControl().setValue(value + '/');
        break;
      case 5: // Depois de 5 caracteres adiciona '/'.
        this.calendarFormControl().setValue(value + '/');
        break;
    }

    if (this.multidateSelect()) {
      switch (value?.length) {
        case 10: // Depois de 10 caracteres adiciona ' - '.
          this.calendarFormControl().setValue(value + ' - ');
          break;
        case 15: // Depois de 15 caracteres adiciona '/'.
          this.calendarFormControl().setValue(value + '/');
          break;
        case 18: // Depois de 18 caracteres adiciona '/'.
          this.calendarFormControl().setValue(value + '/');
          break;
      }
    }
  }

  private _shouldExcludeLetters(value: string | CalendarOutput): boolean {
    if (typeof value === 'string') {
      if (isNaN(parseInt(value, 10))) {
        this.calendarFormControl().setValue(
          value?.substring(0, value.length - 1),
        );
        return true;
      }
    }

    return false;
  }

  private _updateCalendar(value: string | CalendarOutput): void {
    if (typeof value === 'string' && this._componentRef) {
      if (
        (!this.multidateSelect() &&
          value.length === this._maxLengthForSingleDateSelect) ||
        (this.multidateSelect() &&
          value.length === this._maxLengthForMultiDateSelect)
      ) {
        this._componentRef.instance.selectDateBasedOnInput(value);
      }
    }
  }

  private _createCalendarComponent(): void {
    this._componentRef =
      this._viewContainerRef.createComponent(CalendarComponent);
    this._componentRef.instance.dateOutput.subscribe((date) => {
      this._setValue(date);
    });

    const rect = this._elementRef.nativeElement.getBoundingClientRect();
    const nativeElement = this._componentRef.location.nativeElement;

    nativeElement.style.position = 'absolute';
    nativeElement.style.zIndex = '3';
    nativeElement.style.left = '0px';
    if (this.calendarPosition() === 'bottom')
      nativeElement.style.top = `${rect.height + 10}px`;
    if (this.calendarPosition() === 'top') {
      nativeElement.style.top = `0px`;
      nativeElement.style.transform = 'translateY(-100%)';
    }

    this._componentRef.instance.ableToClose.set(true);
    this._componentRef.instance.multiDateSelect.set(this.multidateSelect());
    this._componentRef.instance.quickSelectOptions.set(
      this.quickSelectionOptions(),
    );
    this._componentRef.instance.excludeWeekendsAndHolidays.set(
      this.excludeWeekendsAndHolidays(),
    );
  }

  private _openCalendar(): void {
    if (this._componentRef) this._componentRef.instance.isOpen.set(true);
  }

  private _closeCalendar(): void {
    if (this._componentRef && this._componentRef.instance.ableToClose())
      this._componentRef.instance.isOpen.set(false);
  }

  private _setValue(value: CalendarOutput | null): void {
    if (!value) {
      this.calendarFormControl().reset();
      return;
    }

    this.calendarFormControl().setValue(value, { emitEvent: true });

    const locale =
      this._componentRef.instance.currentLanguage() === 'en'
        ? 'en-US'
        : 'pt-BR';

    if (value instanceof Date) {
      this._elementRef.nativeElement.value = value.toLocaleDateString(locale);
    } else {
      this._elementRef.nativeElement.value = `${value.start.toLocaleDateString(locale)} - ${value.end.toLocaleDateString(locale)}`;
    }

    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    this._elementRef.nativeElement.dispatchEvent(event);
  }

  ngOnDestroy(): void {
    this._componentRef?.destroy();
  }
}
