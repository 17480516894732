export interface AlbumsDTO {
  releases: ReleasesModel[];
}

export interface ReleasesModel {
  releaseExternalId: string;
  imageUrl: string;
  releaseTitle: string;
  artistNames: string;
  releaseType: string;
  labelName: string;
  upc: string;
  totalTracks: string;
  totalDuration: number;
  releaseDate: string;
  alreadyExist: boolean;
  tracks: TrackModel[];

  // Informações adicionais
  open: boolean;
  hover: boolean;
  hash?: string;
  complete: boolean;
}

export interface TrackModel {
  orderNumber: number;
  trackTitle: string;
  artistNames: string;
  isExplicit: boolean;
  isrc: string;
  duration: number;

  // Dados após o upload
  filename?: string;
  hash?: string;
  trackId?: number;

  // Informações adicionais
  incomplete: boolean;
  uploading: boolean;
  failed: boolean;
  isPlaying: boolean;
}
