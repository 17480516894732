import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslocoService } from '@jsverse/transloco';
import {
  MzicArtistLocalService,
  MzicTokenLocalService,
} from '@mzic/mzic-services';

@Injectable()
export class MzicHttpInterceptorInterceptor implements HttpInterceptor {
  protected showAlert = true;

  constructor(
    private readonly _mzicTokenLocalService: MzicTokenLocalService,
    private readonly _mzicArtistLocalService: MzicArtistLocalService,
    private _router: Router,
    private translocoService: TranslocoService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const token = this._mzicTokenLocalService.getTokenLocal();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    const teamId = this._mzicArtistLocalService.getTeamId();
    if (teamId) {
      request = request.clone({
        setHeaders: {
          'TEAM-ID': teamId,
        },
      });
    }

    request = request.clone({
      setHeaders: {
        Accept: '*/*',
      },
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 401) {
          if (
            this._router.url.includes('dashboard') ||
            this._router.url.includes('team-selection')
          ) {
            return this.redirectFromPrivate();
          }

          return throwError('Não autorizado.');
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private redirectFromPrivate(): Observable<HttpEvent<unknown>> {
    this._mzicArtistLocalService.removeAllStorage();
    const currentLang = this.translocoService.getActiveLang();
    // TODO: recuperar do json quando tiver mais linguagens
    const title =
      currentLang === 'pt-br'
        ? 'Por motivos de segurança, precisaremos desconectá-lo. Por favor, faça login novamente.'
        : 'For security reasons, we will need to log you out.\nPlease login again.';

    this._router.navigate(['login']);
    this.showAlertIfNecessary(title);

    return throwError('Não autorizado.');
  }

  // TODO: implementar chamada toast
  private showAlertIfNecessary(title: string): void {
    if (this.showAlert) {
      this.showAlert = false;
      setTimeout(() => {
        alert(title);
        this.showAlert = true;
      }, 500);
    }
  }

  // TODO: implementar no futuro
  private refreshTokenAndRetry(
    originalRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Implemente a lógica para renovar o token usando o refreshToken
    // Certifique-se de que o refreshToken esteja disponível diretamente

    // Verifique se a renovação do token foi bem-sucedida
    const newToken = this._mzicTokenLocalService.getRefreshToken();

    if (newToken) {
      const clonedRequest = originalRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${newToken}`,
        },
      });
      // Reenvie a solicitação original com o novo token
      return next.handle(clonedRequest);
    } else {
      // A renovação do token falhou, deslogue o usuário
      this._mzicArtistLocalService.removeAllStorage();
      this._router.navigate(['login']);

      return throwError('Falha ao renovar o token.');
    }
  }
}
