import { ResponseInterfacePageable } from '../http/response.interface';

export interface ArtistTypeResourceData extends ResponseInterfacePageable {
  content: ArtistTypeResourceContent[];
}

export interface ArtistTypeResourceContent {
  id: number;
  roleId: number;
  name: string;
  priority: number;
}
