export function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

export function padZero(value: number): string {
  return value.toString().padStart(2, '0');
}

export function formatDateAsDayMonthYear(
  date: Date,
  format?: 'YYYY-MM-DD' | 'DD/MM/YYYY',
) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  if (format === 'YYYY-MM-DD') {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}

export function formatDateToYYYYMMDD(dateString: string): string {
  if (dateString.includes('/')) {
    const [month, day, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  return dateString;
}
