import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MzicLabelCreationStateService {
  private labelImageSource = new BehaviorSubject<string | null>(null);
  public labelImage$ = this.labelImageSource.asObservable();

  private imageIdSource = new BehaviorSubject<string | null>(null);
  public imageId$ = this.imageIdSource.asObservable();

  private labelFormDataSource = new BehaviorSubject<any>({});
  public labelFormData$ = this.labelFormDataSource.asObservable();

  constructor() {
    this.initStateFromStorage();
  }

  private initStateFromStorage(): void {
    try {
      const savedImage = localStorage.getItem('label_creation_image');
      const savedImageId = localStorage.getItem('label_creation_image_id');
      const savedFormData = localStorage.getItem('label_creation_form_data');

      if (savedImage) {
        this.labelImageSource.next(savedImage);
      }

      if (savedImageId) {
        this.imageIdSource.next(savedImageId);
      }

      if (savedFormData) {
        this.labelFormDataSource.next(JSON.parse(savedFormData));
      }
    } catch (error) {
      console.error('Erro ao recuperar estado do localStorage', error);
      this.clearState();
    }
  }

  setLabelImage(imageUrl: string): void {
    this.labelImageSource.next(imageUrl);
    localStorage.setItem('label_creation_image', imageUrl);
  }

  setImageId(imageId: string): void {
    this.imageIdSource.next(imageId);
    localStorage.setItem('label_creation_image_id', imageId);
  }

  getLabelImage(): string | null {
    return this.labelImageSource.value;
  }

  getImageId(): string | null {
    return this.imageIdSource.value;
  }

  updateLabelFormData(data: any): void {
    const currentData = this.labelFormDataSource.value;
    const updatedData = { ...currentData, ...data };
    this.labelFormDataSource.next(updatedData);
    localStorage.setItem(
      'label_creation_form_data',
      JSON.stringify(updatedData),
    );
  }

  getLabelFormData(): any {
    return this.labelFormDataSource.value;
  }

  clearState(): void {
    this.labelImageSource.next(null);
    this.imageIdSource.next(null);
    this.labelFormDataSource.next({});
    localStorage.removeItem('label_creation_image');
    localStorage.removeItem('label_creation_image_id');
    localStorage.removeItem('label_creation_form_data');
  }
}
