export interface MusicResourceInterface {
  id: number;
  name: string;
  description: string;
  genre: string;
  explicitContent: true;
  price: number;
  lyrics: string;
  subGenre: string;
  rarity: string;
  file: string;
  streamFile: string;
  video: string;
  editionNumber: number;
  remainingEditionNumber: number;
  releaseDate: string;
  mintDuration: string;
  commentary: string;
  coverFile: string;
  artistContractFile: string;
  artistMusic: [
    {
      artistNames: Array<string>;
      artistType: {
        name: string;
        description: string;
      };
    },
  ];
  artistId: number;
  artistName: string;
  artistProfilePhoto: string;
  duration: number;
  hash: string;
  copyright: {
    registrationBureau: string;
    registrationNumber: string;
    registrationNumberUrl: string;
    copyrightType: string;
    isrc: string;
    songIswc: string;
    registrationDate: string;
    percentOfCopyrightOnOffer: string;
    networkId: string;
    network: string;
    copyrightContractAddress: string;
    copyrightContractAddressUrl: string;
    tokenId: number;
    shareContract: string;
    shareContractUrl: string;
    tokenType: string;
    urlLegalStatus: string;
    interpretedBy: string;
    composedBy: string;
    producedBy: string;
  };
  analytics: {
    items: number;
    owners: number;
    floorPrice: number;
    tradedTotal: number;
    tradedSecondaryMarket: number;
    tradedPrimaryMarket: number;
  };
}

export interface MusicResourceListCalendarInterface {
  timestamp: '2023-10-08 02:13:59';
  status: 'success';
  data: Array<MusicResourceCalendarInterface>;
}

export interface MusicResourceCalendarInterface {
  releaseDate: string;
  name: string;
  description: string;
  hash: string;
  type: string;
}
