import { ReleaseDetailTrackArtist } from "./release-detail-track-artist.interface";

export interface ReleaseDetailTrack {
  name: string;
  version: string;
  fileId: string;
  isrc: string;
  trackLength: number;
  trackId: number;
  artistList: ReleaseDetailTrackArtist[];
}
