import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MzicSvgComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-components-dashboard',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, RouterModule],
  templateUrl: './components-dashboard.component.html',
  styleUrls: ['./components-dashboard.component.scss'],
})
export class ComponentsDashboardComponent {
  menu = [
    { path: 'buttons', label: 'Button' },
    { path: 'icons', label: 'Icon' },
    { path: 'labels', label: 'Label' },
    { path: 'colors', label: 'Color' },
    { path: 'inputs', label: 'Input' },
    { path: 'selects', label: 'Select' },
    { path: 'popovers', label: 'Popover' },
    { path: 'tables', label: 'Table' },
    { path: 'alerts', label: 'Alert' },
    { path: 'tabs', label: 'Tab' },
    { path: 'tooltips', label: 'Tooltip' },
    { path: 'bank-accounts', label: 'Bank Account' },
    { path: 'audio-player', label: 'Audio Player' },
    { path: 'calendars', label: 'Calendar' },
    { path: 'side-floating-menu', label: 'Menu Flutuante' },
    { path: 'loaders', label: 'Loader' },
  ].sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
