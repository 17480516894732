import { Type } from '@angular/core';
import { Route, Routes } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ComponentsDashboardComponent,
  routes as dashboardComponentRoutes,
} from '@mzic/components-dashboard';
import { MzicGuardUserMatch } from '@mzic/mzic-guard';
import { PrivateComponent } from './pages/private/private.component';

class Shell {
  static childRoutes(
    routes: Routes,
    component: Type<unknown> | undefined,
  ): Route {
    return {
      path: '',
      component: component,
      children: routes,
      canActivate: [MzicGuardUserMatch],
    };
  }
}

export const appRoutes: Route[] = [
  Shell.childRoutes(
    [
      {
        path: '',
        loadChildren: () => import('./pages/private/private.module'),
      },
    ],
    PrivateComponent,
  ),
  {
    path: 'components',
    children: dashboardComponentRoutes,
    component: ComponentsDashboardComponent,
    canActivate: [MzicGuardUserMatch],
  },
  {
    path: '',
    loadChildren: () => import('./pages/public/public.module'),
  },
  { path: '**', redirectTo: '' },
];
