import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingPercentComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-loaders',
  standalone: true,
  imports: [CommonModule, LoadingPercentComponent],
  templateUrl: './loaders.component.html',
  styleUrl: './loaders.component.scss',
})
export class LoadersComponent implements OnInit {
  @ViewChild('loading', { static: true }) loading!: LoadingPercentComponent;

  ngOnInit(): void {
    setTimeout(() => {
      this.loading.start();
    }, 4000);
  }
}
