<h1>Popovers</h1>
<div class="components-container">
  <h2>Examples</h2>
  <div>
    <button class="mz-button medium" (click)="openComponent()">
      Open With Component
    </button>
    <button class="mz-button medium" (click)="openTemplate()">
      Open With Template
    </button>
    <button class="mz-button medium" (click)="openConfirm()">
      Open Confirm
    </button>
  </div>
  <h2 style="margin-top: 64px">Layouts</h2>
  <div>
    <div class="mzic-popover modal-popover">
      <div class="header">
        <h3 class="title">Delete this version?</h3>
        <button class="mz-button only-icon small ghost close white">
          <mzic-svg src="assets/icons/mzic-close.svg" />
        </button>
      </div>
      <div class="main">
        <p>This action is permanent and cannot be undone.</p>
      </div>
      <div class="footer">
        <button class="mz-button small secondary">CANCEL</button>
        <button class="mz-button small danger white">DELETE</button>
      </div>
    </div>

    <div class="mzic-popover modal-popover">
      <div class="header">
        <h3 class="title">
          <mzic-svg src="assets/icons/mzic-starts.svg" />
          Feature coming soon
        </h3>
        <button class="mz-button only-icon small ghost close white">
          <mzic-svg src="assets/icons/mzic-close.svg" />
        </button>
      </div>
      <div class="main">
        <p>
          We are preparing the best way to utilize this feature. In the
          meantime, please contact your account manager to complete this action.
        </p>
      </div>
      <div class="footer flex-end">
        <button class="mz-button small">GOT IT</button>
      </div>
    </div>

    <div class="mzic-popover modal-popover">
      <div class="header">
        <h3 class="title">Would you like to upload a new audio file?</h3>
        <button class="mz-button only-icon small ghost close white">
          <mzic-svg src="assets/icons/mzic-close.svg" />
        </button>
      </div>
      <div class="main">
        <p>This action is permanent and cannot be undone.</p>
      </div>
      <div class="footer">
        <button class="mz-button small secondary">CANCEL</button>
        <button class="mz-button small white">UPLOAD AUDIO</button>
      </div>
    </div>

    <div class="mzic-popover modal-popover">
      <div class="header flex-end">
        <button class="mz-button only-icon small ghost close white">
          <mzic-svg src="assets/icons/mzic-close.svg" />
        </button>
      </div>
      <div class="main center">
        <mzic-svg src="assets/icons/mzic-close-fill.svg" class="w50 red" />
        <div class="title">Error</div>
        <p>
          There was an issue with the server or the internet connection. Please
          try again
        </p>
      </div>
      <div class="footer">
        <button class="mz-button small secondary">CLOSE</button>
        <button class="mz-button small danger">TRY AGAIN</button>
      </div>
    </div>
  </div>

  <div>
    <div class="mzic-popover modal-popover medium">
      <div class="header">
        <h3 class="title">Writers and publishers</h3>
        <button class="mz-button only-icon small ghost close white">
          <mzic-svg src="assets/icons/mzic-close.svg" />
        </button>
      </div>
      <div class="main padding">
        <p>
          Lorem IpEsse reprehenderit reprehenderit amet anim aliquip
          exercitation incididunt reprehenderit ullamco velit. Exercitation elit
          cillum aliquip sit tempor aute do incididunt adipisicing magna
          adipisicing nostrud. Aute qui ex et et exercitation. Quis laborum
          officia non duis tempor culpa occaecat amet nisi deserunt sit amet.
          Culpa veniam pariatur sint qui officia amet cillum aliqua irure ad.
          Veniam labore consequat cillum fugiat exercitation reprehenderit do
          sit voluptate
          <br />
          <br />
          eu est nulla occaecat. Aliquip aliqua labore sunt voluptate aute do
          exercitation anim sit qui irure sit. Consectetur nulla aliqua
          incididunt excepteur enim laborum dolore cupidatat. Laborum anim
          adipisicing velit esse dolor amet. Velit ex veniam dolor magna culpa
          laboribts
          <br />
          <br />
          ut deserunt reprehenderit nulla anim ullamco nulla. Aliqua in ea non
          consequat sit occaecat consectetur minim ullamco ea in. Esse cillum
          Lorem adipisicing nisi sit labore ipsum culpa in eu et duis dolor.
          Magna adipisicing ut magna nostrud cupidatat cillum velit laboris in
          ex duis excepteur.
          <br />
          <br />
          Dolor voluptate do Lorem esse consectetur duis exercitation amet.
          Lorem excepteur in pariatur dolore dolore dolore. Eiusmod qui irure
          aute nisi reprehenderit sint labore non laboris laborum magna.
          Deserunt excepteur minim consectetur labore laborum voluptate minim
          occaecat id ut tempor dolor enim.
        </p>
      </div>
      <div class="footer padding">
        <button class="mz-button small secondary">CANCEL</button>
        <button class="mz-button small white" disabled>SAVE</button>
      </div>
    </div>
  </div>

  <div>
    <div class="mzic-popover style-dark-small">
      <div><span class="clickable">10</span></div>
      <div><span class="clickable">20</span></div>
      <div><span class="clickable">30</span></div>
      <div><span class="clickable">40</span></div>
      <div><span class="clickable">All</span></div>
    </div>

    <div class="mzic-popover style-white-small">
      <div class="clickable">
        <mzic-svg src="assets/icons/mzic-edit.svg" />
        <span>Edit</span>
      </div>
      <div class="clickable">
        <mzic-svg src="assets/icons/mzic-edit.svg" />
        <span>Download CSV</span>
      </div>
      <div class="clickable">
        <mzic-svg src="assets/icons/mzic-edit.svg" />
        <span>Download XLSX</span>
      </div>
      <div class="clickable">
        <mzic-svg src="assets/icons/mzic-edit.svg" class="red-500" />
        <span class="red-500">Delete</span>
      </div>
    </div>

    <div class="mzic-popover style-toast">
      <div class="progress" style="width: 70%"></div>
      <div>
        <mzic-svg
          src="assets/icons/mzic-download.svg"
          class="stroke neutral-700 w28" />
        <div class="message-container">
          <div class="main">Preparing download</div>
          <div class="secondary">This might take a moment</div>
        </div>
      </div>
      <div>
        <mzic-svg
          src="assets/icons/mzic-close.svg"
          class="neutral-700 w16 clickable" />
      </div>
    </div>
  </div>

  <div>
    <div class="mzic-popover style-toast">
      <div class="progress" style="width: 70%"></div>
      <div>
        <mzic-svg
          src="assets/icons/mzic-download.svg"
          class="stroke neutral-700 w28" />
        <div class="message-container">
          <div class="main">
            Preparing download with a big title to see style
          </div>
          <div class="secondary">
            This might take a moment and this text is two lines
          </div>
        </div>
      </div>
      <div>
        <mzic-svg
          src="assets/icons/mzic-close.svg"
          class="neutral-700 w16 clickable" />
      </div>
    </div>
    <div class="mzic-popover style-toast medium">
      <div>
        <mzic-svg
          src="assets/icons/mzic-check-mark-filled.svg"
          class="green w28" />
        <div class="message-container">
          <div class="secondary">Download successfull</div>
        </div>
      </div>
      <div>
        <mzic-svg
          src="assets/icons/mzic-close.svg"
          class="neutral-700 w16 clickable" />
      </div>
    </div>
    <div class="mzic-popover style-toast medium">
      <div>
        <mzic-svg src="assets/icons/mzic-close-filled.svg" class="red w28" />
        <div class="message-container">
          <div class="secondary">Download error</div>
        </div>
      </div>
      <div>
        <a class="link-btn">TRY AGAIN</a>
        <mzic-svg
          src="assets/icons/mzic-close.svg"
          class="neutral-700 w16 clickable" />
      </div>
    </div>
  </div>
</div>

<ng-template #templateTeste>
  <div class="header">
    <h3 class="title">Sou um Template</h3>
    <button
      class="mz-button only-icon small ghost close white"
      (click)="closeTemplatePopover(false)">
      <mzic-svg src="assets/icons/mzic-close.svg" />
    </button>
  </div>
  <div class="main">
    <p>Sou um ng-template direto</p>
  </div>
  <div class="footer">
    <button
      class="mz-button small secondary"
      (click)="closeTemplatePopover(false)">
      Cancela
    </button>
    <button class="mz-button small" (click)="closeTemplatePopover(true)">
      Confirma
    </button>
  </div>
</ng-template>
