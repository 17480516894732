<h1>Selects</h1>

<div class="container">
  <div class="row mt-5">
    <h3>Sizes</h3>
    <hr />
    <div class="col-4">
      <label>Select small</label>
      <mzic-select-basic
        [variation]="selectVariationEnum.Small"
        [options]="selectOptionsYear"
        placeholder="Select year"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>Select basic</label>
      <mzic-select-basic
        [options]="selectOptionsYear"
        placeholder="Select year"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>Select loading</label>
      <mzic-select-basic
        [loading]="true"
        [options]="selectOptionsYear"
        placeholder="Select year"></mzic-select-basic>
    </div>
  </div>

  <div class="row mt-5">
    <h3>Scroll, search and disabled state</h3>
    <hr />
    <div class="col-4">
      <label>Select with scroll</label>
      <mzic-select-basic
        [options]="selectOptionsLanguage"
        placeholder="Select language"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>Select with search</label>
      <mzic-select-basic
        [searchable]="true"
        [options]="selectOptionsLanguage"
        placeholder="Select language"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>Select disabled</label>
      <mzic-select-basic
        [options]="selectOptionsYear"
        [disabled]="true"
        placeholder="Select year"></mzic-select-basic>
    </div>
  </div>

  <div class="row mt-5">
    <h3>Highlight and extra title above options</h3>
    <hr />
    <div class="col-4">
      <label>Select with currency highlight</label>
      <mzic-select-basic
        [options]="selectOptionsSongs"
        placeholder="Select song"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>Select with a label in the content</label>
      <mzic-select-basic
        [searchable]="true"
        [optionsLabel]="'New tracks'"
        [options]="selectOptionsAssets"
        placeholder="Select tracks in your assets"></mzic-select-basic>
    </div>
  </div>

  <div class="row mt-5">
    <h3>Multiple selection with complex options</h3>
    <hr />
    <div class="col-4">
      <label>Select simple multiply items</label>
      <mzic-select-basic
        [multiple]="true"
        [options]="selectOptionsArtists"
        placeholder="Select artists"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>Select complex multiply items</label>
      <mzic-select-basic
        [searchable]="true"
        [multiple]="true"
        [optionsLabel]="'New tracks'"
        [options]="selectOptionsAssets"
        placeholder="Select tracks in your assets"></mzic-select-basic>
    </div>
    <div class="col-4">
      <label>
        Select with orientation
        <b>top</b>
      </label>
      <mzic-select-basic
        [orientation]="selectOrientationEnum.Top"
        [searchable]="true"
        [multiple]="true"
        [optionsLabel]="'New tracks'"
        [options]="selectOptionsRoles"
        placeholder="Select a role"></mzic-select-basic>
    </div>
  </div>
  <div class="row mt-5">
    <h3>
      Simple capture with
      <code>&#64;Output</code>
    </h3>
    <hr />
    <div class="row">
      <div class="col-4">
        <div class="mz-form-row vertical">
          <label>Select small</label>
          <mzic-select-basic
            [variation]="selectVariationEnum.Small"
            [options]="selectOptionsYear"
            (optionChange)="handleOptionChange($event)"
            placeholder="Select year"></mzic-select-basic>
          <div class="message">
            Value selected: {{ simpleSelectedOption | json }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <h3>Reactive form</h3>
      <hr />
      <div class="row">
        <div class="col-4">
          <div class="mz-form-row vertical">
            <form [formGroup]="form" (ngSubmit)="handleSubmit()">
              <label>Select reactive form</label>
              <mzic-select-control
                formControlName="years"
                [options]="selectOptionsYear"
                placeholder="Select year"></mzic-select-control>
              <div class="message">
                Value selected: {{ form.get('years')?.value | json }}
              </div>
              <br />
              <button class="mz-button secondary mini" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>

        <div class="col-4">
          <div class="mz-form-row vertical">
            <form
              #ngForm="ngForm"
              [formGroup]="formRequired"
              (ngSubmit)="handleSubmitRequired()">
              <label>
                Select reactive form
                <i>required</i>
              </label>
              <mzic-select-control
                [invalid]="
                  ngForm.submitted && formRequired.controls['music'].invalid
                "
                formControlName="music"
                [options]="selectOptionsMusics"
                placeholder="Select a music"></mzic-select-control>
              <div class="message">
                Value selected:
                {{ formRequired.controls['music'].value | json }}
              </div>
              <br />
              <button class="mz-button secondary mini" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <h3>Simple Select</h3>
      <hr />
      <div class="col-4">
        <label>Select With &#64;Input()</label>
        <mzic-select-simple
          ngDefaultControl
          placeholder="select country"
          [(ngModel)]="simpleSelectModel1"
          (ngModelChange)="increment1($event)"
          [options]="simpleSelectOptions1" />
        <br />
        ngModel: {{ simpleSelectModel1 }}
        <br />
        ngModelChanges: {{ simpleSelectModel1ChangesCount }}
        <br />
        <br />
        <button
          class="mz-button small danger"
          (click)="simpleSelectModel1 = ''">
          Clear
        </button>
        ‎
        <button class="mz-button small" (click)="simpleSelectModel1 = '1'">
          Set Brazil
        </button>
      </div>
      <div class="col-4">
        <label>Select with ng-for &#64;for</label>
        <mzic-select-simple
          ngDefaultControl
          placeholder="select country"
          [(ngModel)]="simpleSelectModel2"
          (ngModelChange)="increment2($event)">
          <mzic-select-simple-option value="10" title="China" />
          <mzic-select-simple-option value="15" title="Russia" />
          <mzic-select-simple-option value="20" title="Japão" />
        </mzic-select-simple>
        <br />
        ngModel: {{ simpleSelectModel2 }}
        <br />
        ngModelChanges: {{ simpleSelectModel2ChangesCount }}
        <br />
        <br />
        <button
          class="mz-button small danger"
          (click)="simpleSelectModel2 = ''">
          Clear
        </button>
        ‎
        <button class="mz-button small" (click)="simpleSelectModel2 = '10'">
          Set China
        </button>
      </div>
    </div>
  </div>
</div>
