export interface ParamsLoginUser {
  username: string;
  email: string;
  password: string;
}

export interface ResponseUserLogin {
  timestamp: string;
  status: string;
  data: UserLoginData;
}

export interface UserLoginData {
  access: {
    expiration: string;
    token: string;
    refreshToken: string;
    requireMfa: boolean;
  };
  user: {
    wallet: string;
    userId: number;
    username: string;
    email: string;
    privacyPolicy: boolean;
    termsOfUse: boolean;
  };
}
