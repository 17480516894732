export type MzicAudioPlayerCtrlAction =
  | 'setFile'
  | 'setPercent'
  | 'setPreviewStart'
  | 'enablePreview'
  | 'play'
  | 'pause';

export interface MzicAudioPlayerFile {
  name: string;
  path: string;
}

export interface MzicAudioPlayerCtrl {
  action: MzicAudioPlayerCtrlAction;
  data?: unknown;
  file?: MzicAudioPlayerFile;
}
