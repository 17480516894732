import { HttpClient, HttpHeaders } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@mzic/mzic-environments';
import {
  createEmptyWalletBalanceData,
  createEmptyWalletFeeSimulateData,
  createEmptyWalletPaymentMethodBankData,
  createEmptyWalletTransactionsData,
  PostExchangeCurrencyBody,
  PostWalletAddBank,
  PostWalletCashout,
  PostWalletCashoutBody,
  PostWalletFilterBankAccount,
  PostWalletFilterBankAccountData,
  PostWalletToken,
  WalletBalance,
  WalletBalanceData,
  WalletFeeSimulate,
  WalletFeeSimulateData,
  WalletFeeSimulateParams,
  WalletListPaymentMethodBank,
  WalletListPaymentMethodBankData,
  WalletTransactions,
  WalletTransactionsData,
  WalletTransactionsParams,
} from '@mzic/mzic-interfaces';
import { httpParams, RegionEnum } from '@mzic/mzic-utils';
import { map, Subject } from 'rxjs';
import { BankConnectorService } from '../bank-connector/bank-connector.service';
import { MzicArtistLocalService } from '../mzic-artist-local/mzic-artist-local.service';
import { UserService } from '../user/user.service';

interface WalletState {
  loading: boolean;
  walletBalance: WalletBalanceData;
  walletTransactions: WalletTransactionsData;
  walletFeeSimulate: WalletFeeSimulateData;
  walletListPaymentMethodBank: WalletListPaymentMethodBankData[];
  walletToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private apiUrl = `${environment.apiUrl}/api/backoffice`;

  // Initialize with a default value, but this will be updated from user profile
  private userRegion: RegionEnum = RegionEnum.BR;

  private state = signal<WalletState>({
    loading: false,
    walletBalance: createEmptyWalletBalanceData(),
    walletTransactions: createEmptyWalletTransactionsData(),
    walletFeeSimulate: createEmptyWalletFeeSimulateData(),
    walletListPaymentMethodBank: createEmptyWalletPaymentMethodBankData(),
    walletToken: '',
  });

  walletBalance = computed(() => this.state().walletBalance);
  walletTransactions = computed(() => this.state().walletTransactions);
  walletFeeSimulate = computed(() => this.state().walletFeeSimulate);
  walletListPaymentMethodBank = computed(
    () => this.state().walletListPaymentMethodBank,
  );
  walletBankAccountMain = computed(
    () =>
      this.state().walletListPaymentMethodBank.filter(
        (bankAccount) => bankAccount.main,
      )[0],
  );
  walletToken = computed(() => this.state().walletToken);

  walletBalance$ = new Subject<WalletBalanceData>();
  walletTransactions$ = new Subject<WalletTransactionsData>();
  walletFeeSimulate$ = new Subject<WalletFeeSimulateData>();
  walletListPaymentMethodBank$ = new Subject<
    WalletListPaymentMethodBankData[]
  >();
  walletToken$ = new Subject<string>();

  constructor(
    private readonly _http: HttpClient,
    private bankConnectorService: BankConnectorService,
    private mzicArtistLocalService: MzicArtistLocalService,
    private userService: UserService,
  ) {
    this.walletBalance$
      .pipe(takeUntilDestroyed())
      .subscribe((walletBalance) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletBalance,
        }));
      });

    this.walletTransactions$
      .pipe(takeUntilDestroyed())
      .subscribe((walletTransactions) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletTransactions,
        }));
      });

    this.walletFeeSimulate$
      .pipe(takeUntilDestroyed())
      .subscribe((walletFeeSimulate) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletFeeSimulate,
        }));
      });

    this.walletListPaymentMethodBank$
      .pipe(takeUntilDestroyed())
      .subscribe((walletListPaymentMethodBank) => {
        this.state.update((state) => ({
          ...state,
          loading: true,
          walletListPaymentMethodBank,
        }));
      });

    this.walletToken$.pipe(takeUntilDestroyed()).subscribe((walletToken) => {
      this.state.update((state) => ({
        ...state,
        loading: true,
        walletToken,
      }));
    });

    // Load user profile to get region information
    this.loadUserRegion();
  }

  // Add a method to load user region from profile
  private loadUserRegion(): void {
    this.userService.getUserProfile().subscribe({
      next: (userProfile) => {
        if (userProfile?.region) {
          try {
            // Check if the region from profile corresponds to a valid RegionEnum value
            const regionValue =
              userProfile.region.toUpperCase() as keyof typeof RegionEnum;
            if (Object.values(RegionEnum).includes(RegionEnum[regionValue])) {
              this.userRegion = RegionEnum[regionValue];
            }
          } catch (error) {
            console.error('Error setting user region:', error);
          }
        }
      },
      error: (error) => {
        console.error('Error fetching user profile for region:', error);
      },
    });
  }

  private teamId(): number {
    const walletTeam = this.mzicArtistLocalService.getWalletTeam();
    return walletTeam?.team?.id || 0;
  }

  getWalletBalance(teamId: number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .get<WalletBalance>(`${this.apiUrl}/wallet/v1/balance`, httpOptions)
      .pipe(map((response) => response.data));
  }

  getWalletTransactions(teamId: number, params?: WalletTransactionsParams) {
    if (params?.pageable?.page) {
      params.pageable.page--;
    }

    const headers = new HttpHeaders().append('TEAM-ID', `${teamId}`);

    return this._http
      .get<WalletTransactions>(`${this.apiUrl}/wallet/v1/transactions`, {
        headers,
        ...httpParams({
          ...params?.pageable,
          transactionType: params?.transactionType,
          transactionDateStart: params?.transactionDateStart,
          transactionDateEnd: params?.transactionDateEnd,
        }),
      })
      .pipe(map((response) => response.data));
  }

  getWalletFeeSimulate(teamId: number, params?: WalletFeeSimulateParams) {
    const headers = new HttpHeaders().append('TEAM-ID', `${teamId}`);

    return this._http
      .get<WalletFeeSimulate>(`${this.apiUrl}/wallet/v1/fee/simulate`, {
        headers,
        ...httpParams(params),
      })
      .pipe(map((response) => response.data));
  }

  getWalletListPaymentMethodBank(teamId: number) {
    const headers = new HttpHeaders().append('TEAM-ID', `${teamId}`);

    return this._http
      .get<WalletListPaymentMethodBank>(
        `${this.apiUrl}/wallet/v1/list/payment-method/bank`,
        {
          headers,
        },
      )
      .pipe(map((response) => response.data));
  }

  postWalletToken(teamId: number) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletToken>(
        `${this.apiUrl}/wallet/v1/generate-token`,
        {},
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  postWalletFilterBankAccount(body: any) {
    return this._http
      .post<PostWalletFilterBankAccount>(
        `${this.apiUrl}/wallet/v1/filter/bank-account`,
        body,
      )
      .pipe(map((response) => response.data));
  }

  postWalletCashOut(
    teamId: number,
    confirmationCode: string,
    body: PostWalletCashoutBody,
  ) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletCashout>(
        `${this.apiUrl}/wallet/v1/cashout?confirmationCode=${confirmationCode}`,
        body,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  postWalletExchangeCurrency(teamId: number, body: PostExchangeCurrencyBody) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletCashout>(
        `${this.apiUrl}/wallet/v1/exchange-currency`,
        body,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  postWalletAddBank(teamId: number, body: PostWalletFilterBankAccountData) {
    const newBody = {
      accessToken: body.accessToken,
      accountsIds: body.accounts.map((account) => account.accountId),
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .post<PostWalletAddBank>(
        `${this.apiUrl}/wallet/v1/add/payment-method/bank`,
        newBody,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  deleteWalletBankAccount(teamId: number, paymentMethodId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': teamId }),
    };

    return this._http
      .delete<PostWalletAddBank>(
        `${this.apiUrl}/wallet/v1/delete/bank-account/${paymentMethodId}`,
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  setUserRegion(region: RegionEnum): void {
    const previousRegion = this.userRegion;
    this.userRegion = region;

    if (previousRegion !== region) {
      console.log(`User region changed from ${previousRegion} to ${region}`);
    }
  }

  getUserRegion(): RegionEnum {
    return this.userRegion;
  }

  openBankConnector(
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
    teamId?: number,
  ): void {
    const id = teamId || this.teamId();
    this.bankConnectorService.getBankConnectorToken(id).subscribe({
      next: (token) => {
        this.bankConnectorService.openBankConnector({
          region: this.userRegion,
          token,
          onSuccess,
          onError,
        });
      },
      error: (error) => {
        onError(error);
      },
    });
  }

  closeBankConnector(): void {
    this.bankConnectorService.closeBankConnector(this.userRegion);
  }
}
