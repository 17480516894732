export interface PaginationInterface<T> {
  timestamp: string;
  status: string;
  data: {
    totalPages: number;
    totalElements: number;
    size: number;
    content: Array<T>;
    number: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    numberOfElements: number;
    pageable: {
      offset: number;
      sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
      };
      pageNumber: number;
      pageSize: number;
      paged: boolean;
      unpaged: boolean;
    };
    first: boolean;
    last: boolean;
    empty: boolean;
  };
  error: {
    error: string;
    title: string;
    code: string;
    trace: string;
    modal: boolean;
  };
  page: number;
  total_pages: number;
  per_page: number;
  total_items: number;
}

/**
 * Quando assumi o projeto, existia dois PaginationInterface, por enquanto dei um nome diferente, e com o tempo vamos pensar em unificar essa interface.
 */
export interface PaginationCreateAccountInterface<T> {
  timestamp: string;
  status: string;
  data: T[];
}
