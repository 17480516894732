import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  MzicSvgComponent,
  MzicTooltipComponent,
  SideFloatingMenuComponent,
  SideFloatingMenuOption,
} from '@mzic/mzic-components';

@Component({
  selector: 'mzic-side-floating-menu-component',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    SideFloatingMenuComponent,
    MzicTooltipComponent,
  ],
  templateUrl: './side-floating-menu.component.html',
  styleUrl: './side-floating-menu.component.scss',
})
export class SideFloatingMenuPageComponent {
  options: SideFloatingMenuOption[] = [
    { id: 'aaa', title: 'A Content' },
    { id: 'bbb', title: 'B Content' },
    { id: 'ccc', title: 'C Content' },
    { id: 'ddd', title: 'D Content' },
  ];
}
