export interface PostWalletAddBank {
  timestamp: string;
  status: string;
  data: string;
}

export interface WalletAddBankBody {
  accessToken?: string;
  accountsIds?: Array<string>;
}
