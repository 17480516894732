import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CalendarComponent,
  CalendarPickerDirective,
  MzicSvgComponent,
} from '@mzic/mzic-components';

@Component({
  selector: 'mzic-calendars',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    CalendarComponent,
    CalendarPickerDirective,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './calendars.component.html',
  styleUrl: './calendars.component.scss',
})
export class CalendarsComponent {
  formControlSingleSelect = new FormControl('', [Validators.required]);
  formControlMultiSelect = new FormControl('', [Validators.required]);
  formControlUpMultiSelect = new FormControl('', [Validators.required]);
  formControlWithIcon = new FormControl('', [Validators.required]);
}
