export interface RoyaltySplitRules {
  include: boolean;
  country: RoyaltySplitRulesCountry;
  dsp: RoyaltySplitRulesDsp;
}

export interface RoyaltySplitRulesDsp {
  id: number;
  name: string;
  distributorStoreId: number;
}

export interface RoyaltySplitRulesCountry {
  id: number;
  name: string;
  nameInPortugueseBr: string;
  flagUrl: string;
  isoAlphaTwo: string;
  isoAlphaThree: string;
  isoCode: string;
  currencyType: string;
}
