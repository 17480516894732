<h1>Tabs</h1>
<div class="components-container">
  <div>
    <div class="mzic-tabs">
      <ul>
        <li>
          <a [ngClass]="{ active: tab1 }" (click)="tab1 = true">Tracks</a>
        </li>
        <li>
          <a [ngClass]="{ active: !tab1 }" (click)="tab1 = false">Releases</a>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <div class="mzic-tabs">
      <ul>
        <li>
          <a [ngClass]="{ active: tab2 }" (click)="tab2 = true">Other Tab</a>
        </li>
        <li>
          <a [ngClass]="{ active: !tab2 }" (click)="tab2 = false">
            Tab 3 With Large Title
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
