<h1>Icons</h1>
<div class="components-container">
  <a class="link" (click)="showWidths = !showWidths">
    Exibir variações de tamanho
  </a>
  <div class="full-width" *ngIf="showWidths">
    <mzic-svg
      src="assets/icons/mzic-plus.svg"
      class="w{{ number }}"
      *ngFor="let number of numbers" />
  </div>

  <a class="link" (click)="showColors = !showColors">
    Exibir variações de cores
  </a>
  <ng-container *ngIf="showColors">
    <div>
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-100" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-200" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-300" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-400" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-500" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-600" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="neutral-700" />
    </div>
    <div>
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-100" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-200" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-300" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-400" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-500" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-600" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-700" />
    </div>
    <div>
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-100" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-200" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-300" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-400" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-500" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-600" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="red-700" />
    </div>
    <div>
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-100" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-200" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-300" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-400" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-500" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-600" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="blue-700" />
    </div>
    <div>
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-100" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-200" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-300" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-400" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-500" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-600" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="yellow-700" />
    </div>
    <div>
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-100" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-200" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-300" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-400" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-500" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-600" />
      <mzic-svg src="assets/icons/mzic-plus.svg" class="green-700" />
    </div>
    <div>
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-100" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-200" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-300" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-400" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-500" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-600" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke neutral-700" />
    </div>
    <div>
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-100" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-200" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-300" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-400" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-500" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-600" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke purple-700" />
    </div>
    <div>
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-100" />
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-200" />
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-300" />
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-400" />
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-500" />
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-600" />
      <mzic-svg src="assets/icons/mzic-check-mark.svg" class="stroke red-700" />
    </div>
    <div>
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-100" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-200" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-300" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-400" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-500" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-600" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke blue-700" />
    </div>
    <div>
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-100" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-200" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-300" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-400" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-500" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-600" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke yellow-700" />
    </div>
    <div>
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-100" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-200" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-300" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-400" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-500" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-600" />
      <mzic-svg
        src="assets/icons/mzic-check-mark.svg"
        class="stroke green-700" />
    </div>
  </ng-container>

  <div class="icons-container">
    <div class="view-mode-container">
      <a (click)="activeViewMode = 'DEFAULT'">Padrão</a>
      <a (click)="activeViewMode = 'PURPLE'">Roxo</a>
      <a (click)="activeViewMode = 'BUTTON'">Button</a>
      <a (click)="activeViewMode = 'BUTTON_WHITE'">Button White</a>
      <a (click)="activeViewMode = 'BUTTON_OUTLINE'">Button Outline</a>
      <a (click)="activeViewMode = 'BUTTON_DISABLED'">Button Disabled</a>
      <a (click)="activeViewMode = 'IMG'">Image</a>
    </div>

    <div class="copy-message">
      <b>Click</b>
      on the icon to copy the image path
    </div>

    <ng-container *ngIf="activeViewMode === 'DEFAULT'">
      <mzic-svg
        (click)="copyIconPath(icon.filename)"
        [src]="'assets/icons/' + icon.filename"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }"
        class="w48 clickable-icon" />
    </ng-container>
    <ng-container *ngIf="activeViewMode === 'PURPLE'">
      <mzic-svg
        (click)="copyIconPath(icon.filename)"
        [src]="'assets/icons/' + icon.filename"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }"
        class="w48 purple clickable-icon" />
    </ng-container>
    <ng-container *ngIf="activeViewMode === 'BUTTON'">
      <button
        (click)="copyIconPath(icon.filename)"
        class="mz-button only-icon clickable-icon"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }">
        <mzic-svg [src]="'assets/icons/' + icon.filename" />
      </button>
    </ng-container>
    <ng-container *ngIf="activeViewMode === 'BUTTON_WHITE'">
      <button
        (click)="copyIconPath(icon.filename)"
        class="mz-button only-icon white clickable-icon"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }">
        <mzic-svg [src]="'assets/icons/' + icon.filename" />
      </button>
    </ng-container>
    <ng-container *ngIf="activeViewMode === 'BUTTON_OUTLINE'">
      <button
        (click)="copyIconPath(icon.filename)"
        class="mz-button only-icon outline clickable-icon"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }">
        <mzic-svg [src]="'assets/icons/' + icon.filename" />
      </button>
    </ng-container>
    <ng-container *ngIf="activeViewMode === 'BUTTON_DISABLED'">
      <button
        disabled
        class="mz-button only-icon"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }">
        <mzic-svg [src]="'assets/icons/' + icon.filename" />
      </button>
    </ng-container>
    <ng-container *ngIf="activeViewMode === 'IMG'">
      <img
        (click)="copyIconPath(icon.filename)"
        [src]="'assets/icons/' + icon.filename"
        *ngFor="let icon of icons"
        [ngClass]="{ stroke: icon.stroke, circle: icon.circle }"
        class="w48 mh48 clickable-icon" />
    </ng-container>
  </div>

  <!-- <div>
    <mzic-svg src="assets/icons/mzic-plus.svg" />
    <mzic-svg src="assets/icons/mzic-plus.svg" class="purple-500" />
    <button class="mz-button only-icon">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button only-icon white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button only-icon outline">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button only-icon" disabled>
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div> -->
</div>
