import { ApiResponse } from '../shared/api-response.interface';

export interface PutSettingsV2Update extends ApiResponse {
  data: PutSettingsV2UpdateData;
}

export type PutSettingsV2UpdateBody = PutSettingsV2UpdateData;

export interface PutSettingsV2UpdateData {
  region: string;
  language: number;
  allowExplicitContent: boolean;
  settingsNotificationDTO: SettingsNotificationDTOData;
}

export interface SettingsNotificationDTOData {
  securityEmail: boolean;
  securityInApp: boolean;
  securitySms: boolean;
  accountActivityEmail: boolean;
  accountActivityInApp: boolean;
  accountActivitySms: boolean;
  teamActivityEmail: boolean;
  teamActivityInApp: boolean;
  teamActivitySms: boolean;
}
