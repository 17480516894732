import { ApiResponse } from '../shared/api-response.interface';

export interface GetCountries extends ApiResponse {
  data: GetCountriesData;
}

export interface GetCountriesData {
  content: GetCountriesDataContent[];
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface GetCountriesDataContent {
  id: number;
  name: string;
  flagUrl: string;
  isoAlphaTwo: string;
  isoAlphaThree: string;
  isoCode: string;
  currencyType: string; // 'AED';
  revelatorCountryId: number;
}

export interface GetCountriesParams {
  lsId?: number[];
  lsIsoAlphaTwo?: string[];
  lsIsoAlphaThree?: string[];
  lsIsoCode?: string[];
  name?: string;
  page?: number;
  size?: number;
  sort?: string;
}
