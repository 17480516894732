<h1>Calendário</h1>

<h4>Calendário com seleção de uma data</h4>
<p>
  Uso básico do calendário. É preciso importar a diretiva no componente ou no
  módulo e usar ela no input. É só usar a diretiva no input e passar o
  formControl para a diretiva.
</p>
<div class="mz-input">
  <input
    type="text"
    mzicCalendarPicker
    [calendarFormControl]="formControlSingleSelect"
    [formControl]="formControlSingleSelect" />
</div>
<br />
<br />
<p>Valor no formControl: {{ formControlSingleSelect.value }}</p>

<hr />

<h4>Calendário com seleção de dias úteis</h4>
<p>
  Uso básico do calendário. É preciso importar a diretiva no componente ou no
  módulo e usar ela no input. É só usar a diretiva no input e passar o
  formControl e o excludeWeekendsAndHolidays como parâmetros.
</p>
<div class="mz-input">
  <input
    type="text"
    mzicCalendarPicker
    [excludeWeekendsAndHolidays]="true"
    [calendarFormControl]="formControlSingleSelect"
    [formControl]="formControlSingleSelect" />
</div>
<br />
<br />
<p>Valor no formControl: {{ formControlSingleSelect.value }}</p>

<hr />

<h4>Calendário com seleção de data inicial e final</h4>
<p>
  As opções de seleção rápida são configuráveis, existe um parâmetro chamado
  "quickSelectionOptions" que é passado para a diretiva usada para abrir o
  calendário, que faz estas opções aparecer ou não.
</p>
<div class="mz-input">
  <input
    type="text"
    mzicCalendarPicker
    [multidateSelect]="true"
    [quickSelectionOptions]="true"
    [calendarFormControl]="formControlMultiSelect"
    [formControl]="formControlMultiSelect" />
</div>
<br />
<br />
<p>Valor no formControl: {{ formControlMultiSelect.value | json }}</p>

<hr />

<h4>Calendário abrindo para cima</h4>
<div class="mz-input">
  <input
    type="text"
    mzicCalendarPicker
    [multidateSelect]="true"
    calendarPosition="top"
    [calendarFormControl]="formControlUpMultiSelect"
    [formControl]="formControlUpMultiSelect" />
</div>
<br />
<br />
<p>Valor no formControl: {{ formControlUpMultiSelect.value | json }}</p>

<hr />

<h4>Calendário, input com ícone</h4>
<div class="mz-form-row for-text icon-left-1">
  <div class="mz-input">
    <div class="mz-input-container">
      <input
        type="text"
        placeholder="All dates"
        mzicCalendarPicker
        [multidateSelect]="true"
        [calendarFormControl]="formControlWithIcon"
        [formControl]="formControlWithIcon"
        [quickSelectionOptions]="true" />
      <div class="icon left">
        <mzic-svg src="assets/icons/mzic-calendar.svg" />
      </div>
    </div>
  </div>
</div>
