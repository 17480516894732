import { ResponseInterfacePageable } from '../http/response.interface';

export interface LanguageResourceData extends ResponseInterfacePageable {
  content: LanguageResource[];
}

export interface LanguageResource {
  id: number;
  name: string;
  code: string;
  flagUrl: string;
}
