import { ResponseInterfacePageable } from '../http/response.interface';
import { ArtistType } from './track-resource.interfaces';

export interface RecentArtist {
  id: number;
  artistExternalId?: number;
  name: string;
  lastName?: string;
  hash?: string;
  photo?: string;
  background?: string;
  email?: string;
  artistAppleId?: string;
  artistSpotifyId?: string;
  uuidViberate?: string;
  fullName?: string;
  socialSecurity?: string;

  // Dados especificos de um writer/publisher
  rightsId?: number;
  sharePercentage?: number;
  publisherName?: string;
}

export interface RecentArtistForm extends RecentArtist {
  checked: boolean;
  visible: boolean;
  photoTemp?: string; // Criei essa propriedade para formatar a URL da imagem e não passar para frente na seleção
  artistTypeId?: number;
}

export interface ArtistTypesResourceData extends ResponseInterfacePageable {
  content: ArtistType[];
}

export interface CreateArtistRequest {
  hash?: string;
  name: string;
  email: string;
  spotifyId?: string;
  appleMusicId?: string;
  uuidViberate?: string;
  socialSecurity?: string;
}

export interface UpdateArtistRequest {
  name?: string;
  photo?: string;
  background?: string;
  email?: string;
  uuidViberate?: string;
  artistAppleId?: string;
  artistSpotifyId?: string;
  fullName?: string;
  socialSecurity?: string | null;
}
