import { RoyaltySplitTeam } from './royalty-split-payee-team.interface';

export interface RoyaltySplitPayee {
  id: number;
  team: RoyaltySplitTeam;
  artistType: RoyaltySplitPayeeArtistType;
  sharePercent: number;
  languageId: number;
  artist: RoyaltySplitPayeeArtist;
  dueValue: number;
  isPriorityPayee: boolean;
  priorityOrder: number;
  hasAcceptedTerms: boolean;
  status: string;
}

export interface RoyaltySplitPayeeArtistType {
  name: string;
  priority: number;
  roleId: number;
  description: string;
  nameInPortugueseBr: string;
}

export interface RoyaltySplitPayeeArtist {
  id: number;
  name: string;
  fullName: string;
  email: string;
  photo: string;
  background: string;
  user: RoyaltySplitPayeeArtisUser;
}

export interface RoyaltySplitPayeeArtisUser {
  id: number;
  firstName: string;
  lastName: string;
  profilePhoto: string;
  backGroundPhoto: string;
}
