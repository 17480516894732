import { Pageable, Sort } from '../http/response.interface';

export interface RoyaltySplitList {
  content: RoyaltySplitListContent[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: Sort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface RoyaltySplitListContent {
  id: number;
  name: string;
  payorWallet: PayorWallet;
  type: string;
  payees: RoyaltySplitListPayee[];
  version: number;
  code: string;
  status: string;

  selected?: boolean;
}

export interface PayorWallet {
  user: User;
}

export interface User {
  id: number;
  username: string;
  profilePhoto: string;
  backGroundPhoto?: string;
}

export interface RoyaltySplitListPayee {
  artist: ArtistPayee;
}

export interface ArtistPayee {
  name: string;
  fullName: string;
  photo: string;
  background?: null | string | string;
  user: User;
}
