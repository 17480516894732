import { ApiResponse } from '../shared/api-response.interface';

export interface PutUserUpdateProfile extends ApiResponse {
  data: PutUserUpdateProfileData;
}

export type PutUserUpdateProfileBody = PutUserUpdateProfileData;

export interface PutUserUpdateProfileData {
  username: string;
  email: string;
  bio: string;
  walletId: number;
  createdAt: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  chainWallet: string;
  cognito: string;
  userId: number;
  birthDate: string;
  verified: boolean;
}
