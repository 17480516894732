import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MzicSvgComponent, SpinnerComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-buttons-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, SpinnerComponent],
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent {}
