import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MzicSvgComponent, MzicTooltipComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-tabs-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, MzicTooltipComponent],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  tab1 = true;
  tab2 = false;
}
