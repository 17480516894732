import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MzicAudioPlayerComponent,
  MzicAudioPlayerCtrl,
  MzicSvgComponent,
} from '@mzic/mzic-components';
import { AudioPlayerService } from '@mzic/mzic-services';
import { Subject } from 'rxjs';

@Component({
  selector: 'mzic-audio-player-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent, MzicAudioPlayerComponent],
  templateUrl: './audio-player.component.html',
  styleUrl: './audio-player.component.scss',
})
export class AudioPlayerComponent implements AfterContentInit, AfterViewInit {
  @ViewChild('audioPlayer') audioElementRef!: ElementRef;

  audioElement!: HTMLAudioElement;

  musicId = 1;
  hashKey = '817a0b9e-a808-4636-9390-9384f372205910';
  segment = 'segment_0000000000.ts';

  localAudioFile = 'assets/audio/short.mp3';
  localAudioFile2 = 'assets/audio/song.mp3';
  localAudioFile3 = 'assets/audio/medium.mp3';

  audioPlayerCtrl1 = new Subject<MzicAudioPlayerCtrl>();
  audioPlayerCtrl2 = new Subject<MzicAudioPlayerCtrl>();
  audioPlayerCtrl3 = new Subject<MzicAudioPlayerCtrl>();
  audio1isPlaying = false;
  audioHlsIsPlaying = false;

  constructor(private audioPlayerService: AudioPlayerService) {}
  ngAfterViewInit(): void {
    this.audioPlayerService.setAudioSource(
      'https://api-dev.mzic.io/api/stream/music/v2/stream/817a0b9e-a808-4636-9390-9384f372205910/stream.m3u8',
    );
    this.audioPlayerService.onPlay$.subscribe(
      () => (this.audioHlsIsPlaying = true),
    );
    this.audioPlayerService.onPause$.subscribe(
      () => (this.audioHlsIsPlaying = false),
    );
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.audioPlayerCtrl1.next({
        action: 'setFile',
        file: { name: 'Michael', path: this.localAudioFile },
      });

      this.audioPlayerCtrl2.next({
        action: 'setFile',
        file: { name: 'Michael', path: this.localAudioFile2 },
      });

      this.audioPlayerCtrl3.next({
        action: 'setFile',
        file: { name: 'Michael', path: this.localAudioFile3 },
      });
    }, 1000);
  }

  playHls() {
    this.audioPlayerService.play();
  }

  pauseHls() {
    this.audioPlayerService.pause();
  }
}
