/**
 * BankConnectorService
 *
 * This service provides a unified interface for connecting to bank accounts through
 * different providers based on the user's region:
 *
 * - For Brazil (BR): Uses Pluggy Connect
 * - For United States (US): Uses Plaid Link
 *
 * Both integrations use the same backend API endpoints:
 * - /wallet/v1/generate-token - Generates a token for the bank connector
 * - /wallet/v1/filter/bank-account - Filters bank accounts returned by the connector
 * - /wallet/v1/add/payment-method/bank - Adds bank accounts as payment methods
 * - /wallet/v1/delete/bank-account/${paymentMethodId} - Deletes a bank account
 * - /wallet/v1/list/payment-method/bank - Lists all bank accounts added
 *
 * The API responses are standardized, so both integrations work with the same data format.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@mzic/mzic-environments';
import { PostWalletToken } from '@mzic/mzic-interfaces';
import { RegionEnum } from '@mzic/mzic-utils';
import { PluggyConnect } from 'pluggy-connect-sdk';
import { Observable, map } from 'rxjs';

export interface PlaidConfiguration {
  product: string[];
}

export interface BankConnectorConfig {
  region: RegionEnum;
  token: string;
  onSuccess: (data: any) => void;
  onError: (error: any) => void;
  onExit?: (data: any) => void;
}

@Injectable({
  providedIn: 'root',
})
export class BankConnectorService {
  private apiUrl = `${environment.apiUrl}/api/backoffice`;
  private pluggyConnect: PluggyConnect | undefined;
  private plaidScript: HTMLScriptElement | null = null;
  private plaidHandler: any = null;
  private readonly _http = inject(HttpClient);

  private plaidConfig: PlaidConfiguration = {
    product: ['auth'],
  };

  /**
   * Open the bank connection modal based on the user's region
   */
  openBankConnector(config: BankConnectorConfig): void {
    if (config.region === RegionEnum.BR) {
      this.openPluggyConnect(config.token, config.onSuccess, config.onError);
    } else {
      this.openPlaidConnect(
        config.token,
        config.onSuccess,
        config.onError,
        config.onExit,
      );
    }
  }

  /**
   * Close the bank connection modal
   */
  closeBankConnector(region: RegionEnum): void {
    if (region === RegionEnum.BR) {
      this.closePluggy();
    } else {
      this.closePlaid();
    }
  }

  /**
   * Get a token for the bank connector
   * Uses the same endpoint for both Pluggy and Plaid since the backend handles both
   */
  getBankConnectorToken(teamId: number): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'TEAM-ID': `${teamId}` }),
    };

    return this._http
      .post<PostWalletToken>(
        `${this.apiUrl}/wallet/v1/generate-token`,
        {},
        httpOptions,
      )
      .pipe(map((response) => response.data));
  }

  // *** PLUGGY SPECIFIC METHODS ***

  /**
   * Initialize and open Pluggy Connect
   */
  private openPluggyConnect(
    token: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
  ): void {
    this.pluggyConnect = new PluggyConnect({
      connectToken: token,
      onSuccess,
      onError,
    });

    this.pluggyConnect.init();
  }

  /**
   * Close Pluggy Connect
   */
  private closePluggy(): void {
    this.pluggyConnect?.destroy();
  }

  // *** PLAID SPECIFIC METHODS ***

  /**
   * Initialize and open Plaid Link
   */
  private openPlaidConnect(
    token: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
    onExit?: (data: any) => void,
  ): void {
    this.loadPlaidScript()
      .then(() => {
        // @ts-expect-error - Plaid is loaded from the external script
        this.plaidHandler = window.Plaid.create({
          token: token,
          clientName: 'MZIC App',
          product: this.plaidConfig.product,
          language: 'en',
          onSuccess: (public_token: string, metadata: any) => {
            onSuccess({
              public_token: public_token,
              metadata: metadata,
            });
          },
          onExit: (err: any, metadata: any) => {
            if (err) {
              onError(err);
            }
            if (onExit) {
              onExit(metadata);
            }
          },
          onEvent: (eventName: string, metadata: any) => {
            console.log('Plaid event:', eventName, metadata);
          },
        });

        this.plaidHandler.open();
      })
      .catch((error) => {
        onError(error);
      });
  }

  /**
   * Close Plaid Link
   */
  private closePlaid(): void {
    if (this.plaidHandler) {
      this.plaidHandler.destroy();
      this.plaidHandler = null;
    }
  }

  /**
   * Load the Plaid Link script
   */
  private loadPlaidScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script#plaid-link-script')) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'plaid-link-script';
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Plaid script'));

      document.head.appendChild(script);
      this.plaidScript = script;
    });
  }
}
