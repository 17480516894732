export interface PostWalletFilterBankAccount {
  timestamp: string;
  status: string;
  data: PostWalletFilterBankAccountData;
}

export interface PostWalletFilterBankAccountData {
  accessToken: string;
  accounts: Account[];
}

export interface Account {
  bankName: string;
  accountType: string;
  accountSubtype: string;
  accountName: string;
  accountNumber: string;
  routingNumber: string;
  accountId: string;
}
