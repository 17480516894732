export interface PostWalletCashout {
  timestamp: string;
  status: string;
  data: PostWalletCashoutData;
}

export interface PostWalletCashoutData {
  currencyType: string;
  eta: string;
  serviceFee: number;
  amount: number;
}

export interface PostWalletCashoutBody {
  currencyType: string;
  proxyId: string | null;
  paymentMethodId: string;
  simulatedFeeId: string;
}
