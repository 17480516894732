import { AssetsAvailable } from './assets-available.interface';
import { RoyaltySplitTeam } from './royalty-split-payee-team.interface';
import { RoyaltySplitPayee } from './royalty-split-payee.interface';
import { RoyaltySplitPayorWallet } from './royalty-split-payor-wallet.interface';
import { RoyaltySplitRules } from './royalty-split-rules.interface';

export interface RoyaltySplitDetail {
  releases: number;
  tracks: number;
  split: RoyaltySplitDetailSplit;
}

export interface RoyaltySplitDetailSplit {
  id: number;
  name: string;
  payorWallet: RoyaltySplitPayorWallet;
  revenueType: string;
  externalFunding: boolean;
  startDate: string;
  expirationDate: string;
  type: string;
  assets: AssetsAvailable[];
  rules: RoyaltySplitRules[];
  payees: RoyaltySplitPayee[];
  teamSplit: RoyaltySplitTeam;
  version: number;
  code: string;
  status: string;
}
