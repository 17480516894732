import { ReleasesListItem } from "./releases-list-item.interface";

export interface ReleasesList {
  totalItemsCount: number;
  pageNumber: number;
  pageSize: number;
  items: ReleasesListItem[];
  additionalCounters: {
    album: number;
    ep: number;
    ringtone: number;
    single: number;
    video: number;
  };
}
