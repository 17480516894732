export interface MediaResourceDownloadTrack {
  timestamp: string;
  status: string;
  data: string;
}

export interface MediaResourceUploadTrack {
  timestamp: string;
  status: string;
  data: MediaResourceUploadTrackData;
}

export interface MediaResourceUploadTrackData {
  hash: string;
  filename: string;
  trackId: number;
}
