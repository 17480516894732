<h1>Colors</h1>
<div class="components-container">
  <div>
    <div class="bg-black"></div>
    <div class="bg-neutral-700"></div>
    <div class="bg-neutral-600"></div>
    <div class="bg-neutral-500"></div>
    <div class="bg-neutral-400"></div>
    <div class="bg-neutral-300"></div>
    <div class="bg-neutral-200"></div>
    <div class="bg-neutral-100"></div>
    <div class="bg-white"></div>
  </div>

  <div>
    <div class="bg-purple-700"></div>
    <div class="bg-purple-600"></div>
    <div class="bg-purple-500"></div>
    <div class="bg-purple-400"></div>
    <div class="bg-purple-300"></div>
    <div class="bg-purple-200"></div>
    <div class="bg-purple-100"></div>
  </div>

  <div>
    <div class="bg-red-700"></div>
    <div class="bg-red-600"></div>
    <div class="bg-red-500"></div>
    <div class="bg-red-400"></div>
    <div class="bg-red-300"></div>
    <div class="bg-red-200"></div>
    <div class="bg-red-100"></div>
  </div>

  <div>
    <div class="bg-yellow-700"></div>
    <div class="bg-yellow-600"></div>
    <div class="bg-yellow-500"></div>
    <div class="bg-yellow-400"></div>
    <div class="bg-yellow-300"></div>
    <div class="bg-yellow-200"></div>
    <div class="bg-yellow-100"></div>
  </div>

  <div>
    <div class="bg-green-700"></div>
    <div class="bg-green-600"></div>
    <div class="bg-green-500"></div>
    <div class="bg-green-400"></div>
    <div class="bg-green-300"></div>
    <div class="bg-green-200"></div>
    <div class="bg-green-100"></div>
  </div>

  <div>
    <div class="bg-blue-700"></div>
    <div class="bg-blue-600"></div>
    <div class="bg-blue-500"></div>
    <div class="bg-blue-400"></div>
    <div class="bg-blue-300"></div>
    <div class="bg-blue-200"></div>
    <div class="bg-blue-100"></div>
  </div>

  <div>
    <div class="black">text</div>
    <div class="neutral-700">text</div>
    <div class="neutral-600">text</div>
    <div class="neutral-500">text</div>
    <div class="neutral-400">text</div>
    <div class="neutral-300">text</div>
    <div class="neutral-200">text</div>
    <div class="neutral-100">text</div>
    <div class="white">text</div>
  </div>

  <div>
    <div class="purple-700">text</div>
    <div class="purple-600">text</div>
    <div class="purple-500">text</div>
    <div class="purple-400">text</div>
    <div class="purple-300">text</div>
    <div class="purple-200">text</div>
    <div class="purple-100">text</div>
  </div>

  <div>
    <div class="red-700">text</div>
    <div class="red-600">text</div>
    <div class="red-500">text</div>
    <div class="red-400">text</div>
    <div class="red-300">text</div>
    <div class="red-200">text</div>
    <div class="red-100">text</div>
  </div>

  <div>
    <div class="yellow-700">text</div>
    <div class="yellow-600">text</div>
    <div class="yellow-500">text</div>
    <div class="yellow-400">text</div>
    <div class="yellow-300">text</div>
    <div class="yellow-200">text</div>
    <div class="yellow-100">text</div>
  </div>

  <div>
    <div class="green-700">text</div>
    <div class="green-600">text</div>
    <div class="green-500">text</div>
    <div class="green-400">text</div>
    <div class="green-300">text</div>
    <div class="green-200">text</div>
    <div class="green-100">text</div>
  </div>

  <div>
    <div class="blue-700">text</div>
    <div class="blue-600">text</div>
    <div class="blue-500">text</div>
    <div class="blue-400">text</div>
    <div class="blue-300">text</div>
    <div class="blue-200">text</div>
    <div class="blue-100">text</div>
  </div>
</div>
