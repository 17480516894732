import { Observable } from 'rxjs';
import { MzicPopoverOLDComponent } from '../mzic-popover-OLD/mzic-popover-OLD.component';

export interface MzicPopoverOLD {
  notifier: Observable<string>;
  instance: MzicPopoverOLDComponent;
}

export interface MzicPopover {
  close: (value?: any) => void;
  onClosed: () => Observable<any>;
}
