import { ResponseInterfacePageable } from '../http/response.interface';

export interface GenreResourceData extends ResponseInterfacePageable {
  content: GenreResource[];
}

export interface GenreResource {
  id: string;
  name: string;
}
