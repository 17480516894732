import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  MzicSvgComponent,
  MzicToggleComponent,
  SelectBasicComponent,
} from '@mzic/mzic-components';
import { SelectOption, SelectVariationEnum } from '@mzic/mzic-interfaces';

@Component({
  selector: 'mzic-inputs-components',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    SelectBasicComponent,
    MzicToggleComponent,
  ],
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
})
export class InputsComponent implements OnInit {
  @Input() index!: number;
  color = '';

  selectOptionsYear: SelectOption[] = [
    { id: 2004, info: { title: '2004' } },
    { id: 2005, info: { title: '2005' } },
    { id: 2006, info: { title: '2006' } },
    { id: 2007, info: { title: '2007' } },
  ];

  selectVariationEnum = SelectVariationEnum;

  bigValue =
    'some big value here na na na na na na na na na na na na na na na na na na na na na na na na na na';

  inputIcons = [
    { path: 'mzic-pencil.svg', tag: 'svg' },
    { path: 'mzic-plus.svg', tag: 'svg' },
    { path: 'mzic-trash.svg', tag: 'svg' },
    { path: 'mzic-search.svg', tag: 'svg' },
    { path: 'mzic-close-filled.svg', tag: 'svg' },
    { path: 'mzic-eye.svg', tag: 'svg' },
    { path: 'mzic-lock.svg', tag: 'svg' },
    { path: 'mzic-lock.svg', tag: 'svg' },
    { path: 'mzic-play.svg', tag: 'svg' },
    { path: 'mzic-clock.svg', tag: 'svg' },
    { path: 'country-brazil.svg', tag: 'img' },
  ];

  selectedInputIcon = this.inputIcons[0];

  input1IsFocused = false;
  input2IsFocused = false;
  input3IsFocused = false;
  input4IsFocused = false;
  input5IsFocused = false;
  input6IsFocused = false;

  ngOnInit(): void {
    if (this.index && this.index === 1) {
      this.color = 'black';
    }
  }
}
