<h1>Loaders</h1>

<div class="container">
  <div class="row mt-5">
    <div class="col-12">
      <h3>Inicio automático</h3>
      <p>
        Por padrão, o componente
        <code>mzic-loading-percent</code>
        inicia automaticamente.
      </p>
      <mzic-loading-percent />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <h3>Inicio manual</h3>
      <p>
        Para iniciar manualmente, basta chamar o método
        <code>start()</code>
        .
      </p>
      <mzic-loading-percent #loading [automatic]="false" />
    </div>
  </div>
</div>
