import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { MzicTokenLocalService } from '@mzic/mzic-services';

export const MzicGuardUserMatch: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
) => {
  const tokenService = inject(MzicTokenLocalService);
  const router = inject(Router);
  const existsToken = tokenService.getTokenLocal();
  if (existsToken) {
    return true;
  } else {
    router.navigate(['home']);
    return false;
  }
};
