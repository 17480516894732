import { AssetsAvailable } from './assets-available.interface';

export interface AssetsAvailableResponse {
  timestamp: string;
  status: string;
  data: AssetsAvailable[];
}

export function createEmptyAssetsAvailable(): AssetsAvailable[] {
  return [
    {
      release: {
        id: 0,
        hash: '',
        title: '',
        titleVersion: '',
        artistNames: '',
        hasReleased: false,
        upc: '',
        releaseDate: '',
        releaseTypeEnum: '',
        label: {
          id: '',
          imageId: '',
          name: '',
          description: '',
        },
        tracks: [],
        eligibleForSplit: true,
        checked: false,
        childrenOpened: false,
      },
      track: {
        id: 0,
        hash: '',
        title: '',
        titleVersion: '',
        artistNames: '',
        eligibleForSplit: {
          eligible: false,
          reason: '',
          ruleNotEligible: {
            countryList: [],
            dspList: [],
          },
        },
        isrc: '',
        label: {
          id: '',
          imageId: '',
          name: '',
          description: '',
        },
        checked: false,
      },
    },
  ];
}
