export type MzicPopoverPosition =
  | 'pageCenter'
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'center'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight'
  | 'inherit';
