import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { SpotifyAvatarComponent } from '../spotify-avatar/spotify-avatar.component';

@Component({
  selector: 'mzic-spotify-hero',
  standalone: true,
  imports: [CommonModule, SpotifyAvatarComponent, TranslocoModule],
  providers: [
    provideTranslocoScope({
      scope: 'mzic-spotify-hero',
      alias: 'lang',
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './spotify-hero.component.html',
  styleUrls: ['./spotify-hero.component.scss'],
})
export class SpotifyHeroComponent {
  @Input({ required: true }) imageUri!: string;
  @Input({ required: true }) artistName!: string;
  @Input({ required: true }) listeners!: number;
  @Input({ required: true }) followers!: number;
  @Input() fullWidth = false;
  @Input() smallHeight = false;
}
