import { ActivityLog } from '../activity-logs/activity-log.model';
import { ApiResponse } from '../shared/api-response.interface';

export interface GetActivityLog extends ApiResponse {
  data: GetActivityLogData;
}

export interface GetActivityLogParams {
  page: number;
  size: number;
  // sort?: string;
  sort?: string;
  search?: string;
  labelIds?: number[];
  startDate?: string;
  endDate?: string;
}

export interface GetActivityLogData {
  totalPages: number;
  totalElements: number;
  size: number;
  content: ActivityLog[];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  pageable: {
    offset: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  first: boolean;
  last: boolean;
  empty: boolean;
}
