export interface Payee {
  id?: number;
  artistExternalId?: number;
  name?: string;
  photo?: string;
  email?: string;
  artistAppleId?: string;
  artistSpotifyId?: string;
  uuidViberate?: string;
  fullName?: string;
  socialSecurity?: string;

  // Especific fields of the payee
  checked?: boolean;
  sharePercentage?: number;
  fixedPercentage?: boolean;
  artistTypeId?: number;
  languageId?: number;
  new?: boolean;
}
