<h1>Inputs</h1>
<div class="components-container">
  <div>
    <div class="mz-form-row for-image">
      <div class="mz-label">
        <label>Upload a photo</label>
      </div>
      <div class="mz-input" (click)="artistInput.click()">
        <input #artistInput type="file" class="hide" />
        <div class="camera-icon"></div>
        <div class="image-text">
          <span class="primary">Choose a photo to upload</span>
          <span class="secondary">PNG, JFIF, JPEG or JPG images</span>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>Track Title</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Enter track title" required />
      </div>
    </div>

    <div class="mz-form-row for-text medium">
      <div class="mz-label">
        <label>Input Medium</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Enter track title" required />
      </div>
    </div>

    <div class="mz-form-row for-text small">
      <div class="mz-label">
        <label>Input Small</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Enter track title" required />
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>
          Required Field
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Enter track title" required />
      </div>
    </div>

    <div class="mz-form-row for-text icon-right-1">
      <div class="mz-label">
        <label>
          Input Error
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            placeholder="Enter track title"
            [value]="bigValue"
            class="error"
            required />
          <div class="icon right">
            <mzic-svg src="assets/icons/mzic-close-filled.svg" class="red" />
          </div>
        </div>
        <div class="message error">Please type a valid phone number.</div>
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>
          Input Error (Deprecated)
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter track title"
          value="Wrong Value"
          class="error"
          required />
        <div class="message error">Please type a valid phone number.</div>
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>Input Error (Hint)</label>
        <p>Se você precisar de um hint sem ter dependencia de outras classes</p>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter track title"
          value="Wrong Value"
          class="error"
          required />
        <div class="form-hint-error form-hint--with-space">
          Please type a valid phone number.
        </div>
      </div>
    </div>

    <div class="mz-form-row for-text icon-right-1">
      <div class="mz-label">
        <label>
          Input Success
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            placeholder="Enter track title"
            value="+55179887291"
            class="success"
            required />
          <div class="icon right">
            <mzic-svg
              src="assets/icons/mzic-check-mark-filled.svg"
              class="green" />
          </div>
        </div>
        <div class="message success">Verified number</div>
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>
          Input Success (Deprecated)
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter track title"
          value="+55179887291"
          class="success"
          required />
        <div class="message success">Verified number</div>
      </div>
    </div>

    <div class="mz-form-row vertical">
      <div class="mz-label">
        <label>Radio Buttons grouped horizontal</label>
      </div>

      <div class="mzic-radio-containers">
        <div class="mzic-radio-container">
          <input type="radio" class="mzic-radio" />
          <label for="alrSpotYes">All DSPs</label>
        </div>
        <div class="mzic-radio-container">
          <input type="radio" class="mzic-radio" />
          <label for="alrSpotYes">All except</label>
        </div>
        <div class="mzic-radio-container">
          <input type="radio" class="mzic-radio" />
          <label for="alrSpotYes">Only</label>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio">
      <div class="mz-label">
        <label>
          Radio Option
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input type="radio" id="html" name="fav_language" value="HTML" />
            <label for="html">HTML</label>
          </div>
          <div>
            <input type="radio" id="css" name="fav_language" value="CSS" />
            <label for="css">CSS</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio disabled">
      <div class="mz-label">
        <label>
          Radio Option [Disabled]
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input
              type="radio"
              id="radiodis1"
              name="radiodisabled"
              value="Yes"
              [checked]="true"
              disabled />
            <label for="radiodis1">Yes</label>
          </div>
          <div>
            <input
              type="radio"
              id="radiodis2"
              name="radiodisabled"
              value="No"
              disabled />
            <label for="radiodis2">No</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio">
      <div class="mz-label">
        <label>
          Yes or No
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input type="radio" id="html" name="fav_language" value="HTML" />
            <label for="html">Um texto maior</label>
          </div>
          <div>
            <input
              type="radio"
              id="css"
              name="fav_language"
              value="CSS"
              checked />
            <label for="css">Outra opção grande</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio">
      <div class="mz-label">
        <label>
          Big Text
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input type="radio" id="html" name="fav_language" value="HTML" />
            <label for="html">
              Yes, funding comes partly or fully from external sources
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="css"
              name="fav_language"
              value="CSS"
              checked />
            <label for="css">No</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio">
      <div class="mz-label">
        <label>
          One Option Width 100%
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input full">
        <div>
          <div>
            <input type="radio" id="html" name="fav_language" value="HTML" />
            <label for="html">
              Yes, funding comes partly or fully from external sources
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio">
      <div class="mz-label">
        <label>
          Yes or No Error
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input
              type="radio"
              id="html"
              name="fav_language"
              value="HTML"
              class="error" />
            <label for="html">HTML</label>
          </div>
          <div>
            <input
              type="radio"
              id="css"
              name="fav_language"
              value="CSS"
              class="error" />
            <label for="css">CSS</label>
          </div>
        </div>
        <div class="message error">Please lorem ipsum lorem ipsum</div>
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>Title version</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Enter title version" />
        <button class="mz-button small tertiary">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Localize your track
        </button>
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>Title version</label>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter title version"
          value="Lorem Ipsum" />
        <div class="mz-item-option">
          <div class="start">
            <img src="assets/icons/flag-brazil.svg" alt="Brazil" />
          </div>
          <div class="mid">
            <strong>Teste de titulo lorem ipsum</strong>
            <small>Lorem ipssum</small>
          </div>
          <div class="end">
            <button class="mz-button only-icon small secondary delete">
              <mzic-svg src="assets/icons/mzic-trash.svg" />
            </button>
          </div>
        </div>

        <button class="mz-button small tertiary">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Localize your track
        </button>
      </div>
    </div>

    <div class="mz-form-row for-text disabled">
      <div class="mz-label">
        <label>Title version Disabled</label>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter title version"
          value="Lorem Ipsum"
          disabled />
        <button class="mz-button small tertiary" disabled>
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Localize your track
        </button>
      </div>
    </div>
    <div class="mz-form-row for-text disabled locked">
      <div class="mz-label">
        <label>Title Version Locked</label>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter title version"
          value="Lorem Ipsum"
          disabled />
      </div>
    </div>
    <div class="mz-form-row for-textarea disabled">
      <div class="mz-label">
        <label>TextArea Disabled</label>
      </div>
      <div class="mz-input">
        <textarea disabled placeholder="Type here.."></textarea>
        <button class="mz-button small tertiary" disabled>
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Localize your track
        </button>
      </div>
    </div>

    <div class="mz-form-row for-radio disabled">
      <div class="mz-label">
        <label>
          Radio Disabled
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input
              type="radio"
              id="html"
              name="fav_language_disabled"
              value="HTML"
              disabled />
            <label for="html">HTML</label>
          </div>
          <div>
            <input
              type="radio"
              id="css"
              name="fav_language_disabled"
              value="CSS"
              checked
              disabled />
            <label for="css">CSS</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-radio">
      <div class="mz-label">
        <label>
          Small/Inner/Sub Form Row
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input type="radio" id="soon" name="when_release" value="soon" />
            <label for="soon">As soon as possible</label>
          </div>
          <div>
            <input
              type="radio"
              id="specific_date"
              name="when_release"
              value="specific" />
            <label for="specific_date">On a specific date</label>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row sub-row for-text">
      <div class="mz-label">
        <label>
          Date
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Select date" required />
      </div>
    </div>

    <div class="mz-form-row for-text">
      <div class="mz-label">
        <label>Title version</label>
      </div>
      <div class="mz-input">
        <input
          type="text"
          placeholder="Enter title version"
          value="Lorem Ipsum" />
        <div class="mz-item-option">
          <div class="start">
            <img src="assets/icons/flag-brazil.svg" alt="Brazil" />
          </div>
          <div class="mid">
            <strong>Teste de titulo lorem ipsum</strong>
            <small>Lorem ipssum</small>
          </div>
          <div class="end">
            <button class="mz-button only-icon small secondary delete">
              <mzic-svg src="assets/icons/mzic-trash.svg" />
            </button>
          </div>
        </div>

        <div class="mz-item-option">
          <div class="start">
            <img src="assets/icons/flag-colombia.svg" alt="Colombia" />
          </div>
          <div class="mid">
            <strong>Teste de titulo lorem ipsum</strong>
            <small>Lorem ipssum</small>
          </div>
          <div class="end">
            <button class="mz-button only-icon small secondary delete">
              <mzic-svg src="assets/icons/mzic-trash.svg" />
            </button>
          </div>
        </div>

        <button class="mz-button small tertiary">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Localize your track
        </button>
      </div>
    </div>

    <div class="mz-form-row for-button">
      <div class="mz-label">
        <label>
          Main artist
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <button class="mz-button small tertiary">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Add other key artists
        </button>
      </div>
    </div>

    <div class="mz-form-row for-button">
      <div class="mz-label">
        <label>
          Main artist
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <button class="mz-button small tertiary error">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Add other key artists
        </button>
        <div class="message error">Please lorem ipsum lorem ipsum</div>
      </div>
    </div>

    <div class="mz-form-row for-button">
      <div class="mz-label">
        <label>
          Main artist
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div class="mz-item-option">
          <div class="start">
            <img src="assets/icons/flag-brazil.svg" alt="Brazil" />
          </div>
          <div class="mid">
            <strong>Teste de titulo lorem ipsum</strong>
            <small>Lorem ipssum</small>
          </div>
          <div class="end">
            <button class="mz-button only-icon small secondary delete">
              <mzic-svg src="assets/icons/mzic-trash.svg" />
            </button>
          </div>
        </div>
        <button class="mz-button small tertiary">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Add other key artists
        </button>
      </div>
    </div>

    <div class="mz-form-row for-button">
      <div class="mz-label">
        <label>Key artists Locked</label>
      </div>
      <div class="mz-input">
        <div class="mz-item-option locked" *ngFor="let item of [1, 2, 3]">
          <div class="start">
            <img src="assets/images/mocks/alok.png" class="large" />
          </div>
          <div class="mid">
            <strong>Alok</strong>
            <small>Artist</small>
          </div>
          <div class="end">
            <img src="assets/icons/mzic-spotify-icon.svg" />
            <img src="assets/icons/mzic-apple-music-icon.svg" />
          </div>
        </div>
        <button class="mz-button small tertiary">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          Add other key artists
        </button>
      </div>
    </div>

    <div class="mz-form-row for-button">
      <div class="mz-label">
        <label>Key artists small</label>
      </div>
      <div class="mz-input">
        <div class="mz-item-option small secondary">
          <div class="start">
            <img src="assets/images/mocks/alok.png" />
          </div>
          <div class="mid">
            <strong>Alok</strong>
          </div>
          <div class="end">
            <img src="assets/icons/mzic-spotify-icon.svg" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-button">
      <div class="mz-label">
        <label>Main artist Locked</label>
      </div>
      <div class="mz-input">
        <div class="mz-item-option locked">
          <div class="start">
            <img src="assets/icons/flag-brazil.svg" alt="Brazil" />
          </div>
          <div class="mid">
            <strong>Teste de titulo lorem ipsum</strong>
            <small>Lorem ipssum</small>
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-select">
      <div class="mz-label">
        <label>
          Select Default
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <mzic-select-basic
          [options]="selectOptionsYear"
          placeholder="Select year"></mzic-select-basic>
      </div>
    </div>

    <div class="mz-form-row for-select disabled">
      <div class="mz-label">
        <label>
          Select Disabled
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <mzic-select-basic
          [disabled]="true"
          [options]="selectOptionsYear"
          placeholder="Select year"></mzic-select-basic>
      </div>
    </div>

    <div class="mz-form-row for-select auto">
      <div class="mz-label">
        <label>
          Select Auto Width
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <mzic-select-basic
          [options]="selectOptionsYear"
          placeholder="Select year"></mzic-select-basic>
      </div>
    </div>

    <div class="mz-form-row for-select auto small">
      <div class="mz-label">
        <label>
          Select Auto Small
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <mzic-select-basic
          [variation]="selectVariationEnum.Small"
          [options]="selectOptionsYear"
          placeholder="Select year"></mzic-select-basic>
      </div>
    </div>

    <div class="mz-form-row for-textarea">
      <div class="mz-label">
        <label>
          Lyrics
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <textarea placeholder="Type or paste your lyrics here"></textarea>
        <button class="mz-button small tertiary">Or upload the file</button>
      </div>
    </div>

    <div class="mz-form-row for-toggle">
      <div class="mz-label">
        <label>Checkbox</label>
      </div>
      <div class="mz-input">
        <mzic-toggle />
      </div>
    </div>

    <div class="mz-form-row for-paragraph">
      <p>
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English
      </p>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-input">
        <input type="text" placeholder="Placeholder" />
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text field</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Placeholder" />
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-input">
        <div class="slot-start">
          <mzic-svg src="assets/icons/mzic-search.svg" class="w20" />
        </div>
        <input type="text" placeholder="Search..." />
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>
          Share % (With Input Sufix)
          <span class="required">*</span>
        </label>
        <p>Composer's percentage of the publisher's share</p>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input type="text" />
          <div class="input-prefix">50/100%</div>
        </div>
      </div>
    </div>

    <div class="mz-form-row vertical for-radio">
      <div class="mz-label">
        <label>Artist already on Spotify?</label>
        <p>Required when distributing to Spotify.</p>
      </div>
      <div class="mz-input">
        <div>
          <div>
            <input type="radio" id="alrSpotYes" name="alrSpot" value="Yes" />
            <label for="alrSpotYes">Yes</label>
          </div>
          <div>
            <input type="radio" id="alrSpotNo" name="alrSpot" value="No" />
            <label for="alrSpotNo">No, create a new Spotify profile</label>
          </div>
        </div>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Artist Spotify Id" />
        <div class="input-tip">
          Go to your artist page on Spotify and copy-paste the numeric part of
          the URL. e.g. 22bE4uQ6baNwSHPVcDxLCe
        </div>
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text field with description</label>
        <p>
          Set a new exclusive username to transact and engage with the MZIC
          community.
        </p>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Placeholder" />
      </div>
    </div>
    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text field with success</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Placeholder" class="success" />
        <div class="message success">Congratulations success message</div>
      </div>
    </div>
    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text field with error</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Placeholder" class="error" />
        <div class="message error">Please type a valid phone number.</div>
      </div>
    </div>
    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text field with message</label>
      </div>
      <div class="mz-input">
        <input type="text" placeholder="Placeholder" />
        <div class="message">Input information if needed</div>
      </div>
    </div>
    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text Field</label>
      </div>
      <div class="mz-input">
        <div class="slot-start">
          <mzic-svg src="assets/icons/flag-brazil-square.svg" />
        </div>
        <input type="password" placeholder="Placeholder" />
      </div>
    </div>
    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text Password</label>
      </div>
      <div class="mz-input">
        <div class="slot-start password">
          <button
            class="mz-button small ghost only-icon white"
            (click)="
              passInput.type =
                passInput.type == 'password' ? 'text' : 'password'
            ">
            <mzic-svg src="assets/icons/mzic-eye.svg" />
          </button>
        </div>
        <input
          #passInput
          type="password"
          placeholder="Placeholder"
          value="admin123" />
      </div>
    </div>
    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text Field</label>
      </div>
      <div class="mz-input">
        <input
          type="password"
          placeholder="Placeholder"
          class="with-slot-end" />
        <div class="slot-end">
          <mzic-svg src="assets/icons/flag-brazil-square.svg" />
        </div>
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text Password</label>
      </div>
      <div class="mz-input">
        <input
          #passInput2
          type="password"
          placeholder="Placeholder"
          class="with-slot-end password" />
        <div class="slot-end password">
          <button
            class="mz-button small ghost only-icon white"
            (click)="
              passInput2.type =
                passInput2.type == 'password' ? 'text' : 'password'
            ">
            <mzic-svg src="assets/icons/mzic-eye.svg" />
          </button>
        </div>
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text Password Success</label>
      </div>
      <div class="mz-input">
        <input
          #passInput3
          type="password"
          placeholder="Placeholder"
          class="with-slot-end password success" />
        <div class="slot-end password">
          <button
            class="mz-button small ghost only-icon white"
            (click)="
              passInput3.type =
                passInput3.type == 'password' ? 'text' : 'password'
            ">
            <mzic-svg src="assets/icons/mzic-eye.svg" />
          </button>
        </div>
      </div>
    </div>

    <div class="mz-form-row vertical for-text">
      <div class="mz-label">
        <label>Text Password Error</label>
      </div>
      <div class="mz-input">
        <input
          #passInput4
          type="password"
          placeholder="Placeholder"
          class="with-slot-end password error" />
        <div class="slot-end password">
          <button
            class="mz-button small ghost only-icon white"
            (click)="
              passInput4.type =
                passInput4.type == 'password' ? 'text' : 'password'
            ">
            <mzic-svg src="assets/icons/mzic-eye.svg" />
          </button>
        </div>
      </div>
    </div>

    <div class="mz-form-row vertical for-image">
      <div class="mz-label">
        <label>Upload a photo</label>
      </div>
      <div class="mz-input" (click)="artistInput.click()">
        <input #artistInput type="file" class="hide" />
        <div class="camera-icon"></div>
        <div class="image-text">
          <span class="primary">Choose a photo to upload</span>
          <span class="secondary">PNG, JFIF, JPEG or JPG images</span>
        </div>
      </div>
    </div>

    <div class="input-icons-toolbox-container">
      <label>Exibir o ícone:</label>
      <div class="icons">
        <ng-container *ngFor="let icon of inputIcons">
          <img
            (click)="selectedInputIcon = icon"
            [src]="'assets/icons/' + icon.path"
            *ngIf="icon.tag === 'img'"
            class="w34" />
          <mzic-svg
            *ngIf="icon.tag === 'svg'"
            (click)="selectedInputIcon = icon"
            [src]="'assets/icons/' + icon.path" />
        </ng-container>
      </div>
    </div>

    <div class="mz-form-row for-text icon-left-1-small icon-right-1-small">
      <div class="mz-label">
        <label>Input Icon - Small</label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            [value]="bigValue"
            (focus)="input1IsFocused = true"
            (blur)="input1IsFocused = false"
            [placeholder]="input1IsFocused ? '' : 'type here..'" />
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input1IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input1IsFocused" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-text icon-left-2-small icon-right-2-small">
      <div class="mz-label">
        <label>Input Double Icon - Small</label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            [value]="bigValue"
            (focus)="input2IsFocused = true"
            (blur)="input2IsFocused = false"
            [placeholder]="input2IsFocused ? '' : 'type here..'" />
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input2IsFocused" />
          </div>
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input2IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input2IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input2IsFocused" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-text icon-left-1 icon-right-1">
      <div class="mz-label">
        <label>Input Icon - Default</label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            [value]="bigValue"
            (focus)="input3IsFocused = true"
            (blur)="input3IsFocused = false"
            [placeholder]="input3IsFocused ? '' : 'type here..'" />
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input3IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input3IsFocused" />
          </div>
        </div>
      </div>
    </div>
    <div class="mz-form-row for-text icon-left-2 icon-right-2">
      <div class="mz-label">
        <label>Input Double Icon - Default</label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            [value]="bigValue"
            (focus)="input4IsFocused = true"
            (blur)="input4IsFocused = false"
            [placeholder]="input4IsFocused ? '' : 'type here..'" />
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input4IsFocused" />
          </div>
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input4IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input4IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input4IsFocused" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-text icon-left-1-large icon-right-1-large">
      <div class="mz-label">
        <label>Input Icon - Large</label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            [value]="bigValue"
            (focus)="input5IsFocused = true"
            (blur)="input5IsFocused = false"
            [placeholder]="input5IsFocused ? '' : 'type here..'" />
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input5IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input5IsFocused" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-text icon-left-2-large icon-right-2-large">
      <div class="mz-label">
        <label>Input Double Icon - Large</label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <input
            type="text"
            [value]="bigValue"
            (focus)="input6IsFocused = true"
            (blur)="input6IsFocused = false"
            [placeholder]="input6IsFocused ? '' : 'type here..'" />
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input6IsFocused" />
          </div>
          <div class="icon left">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input6IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input6IsFocused" />
          </div>
          <div class="icon right">
            <img
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'img'" />
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'"
              [class.purple]="input6IsFocused" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-select icon-left-1-small">
      <div class="mz-label">
        <label>
          Select Icon - Small
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <mzic-select-basic
            [options]="selectOptionsYear"
            placeholder="Select year"></mzic-select-basic>
          <div class="icon left">
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-select icon-left-1">
      <div class="mz-label">
        <label>
          Select Icon - Default
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <mzic-select-basic
            [options]="selectOptionsYear"
            placeholder="Select year"></mzic-select-basic>
          <div class="icon left">
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'" />
          </div>
        </div>
      </div>
    </div>

    <div class="mz-form-row for-select icon-left-1-large">
      <div class="mz-label">
        <label>
          Select Icon - Large
          <span class="required">*</span>
        </label>
      </div>
      <div class="mz-input">
        <div class="mz-input-container">
          <mzic-select-basic
            [options]="selectOptionsYear"
            placeholder="Select year"></mzic-select-basic>
          <div class="icon left">
            <mzic-svg
              [src]="'assets/icons/' + selectedInputIcon.path"
              *ngIf="selectedInputIcon.tag === 'svg'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
