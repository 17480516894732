<style>
  .mzic-bank-account {
    max-width: 500px;
  }
</style>

<h1>Bank Account</h1>
<div class="components-container">
  <div>
    <div class="mzic-bank-account">
      <div>
        <div class="icon">
          <mzic-svg src="assets/icons/mzic-bank.svg" class="purple" />
        </div>
        <div class="text">
          <div class="title">Nenhuma conta cadastrada</div>
          <p>Você ainda não adicionou nenhuma conta</p>
        </div>
      </div>
      <div>
        <button class="mz-button small">ADICIONAR</button>
      </div>
    </div>

    <div class="mzic-bank-account">
      <div>
        <div class="icon">
          <mzic-svg src="assets/icons/mzic-bank.svg" class="purple" />
        </div>
        <div class="text">
          <div class="title">Santander S.A</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div></div>
    </div>

    <div class="mzic-bank-account">
      <div>
        <div class="icon">
          <img src="assets/icons/bank-round-caixa.png" />
        </div>
        <div class="text">
          <div class="title">Caixa</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div>
        <button class="mz-button small secondary">TROCAR</button>
      </div>
    </div>

    <div class="mzic-bank-account">
      <div>
        <div class="icon">
          <img src="assets/icons/bank-round-bradesco.png" />
        </div>
        <div class="text">
          <div class="title">Bradesco S.A</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div>
        <div class="mz-form-row for-radio">
          <div class="mz-input">
            <div>
              <div>
                <input type="radio" name="selected" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mzic-bank-account is-checked-true">
      <div>
        <div class="icon">
          <img src="assets/icons/bank-round-banco-do-brasil.png" />
          <img src="assets/icons/flag-brazil.png" class="flag" />
        </div>
        <div class="text">
          <div class="title">Banco do Brasil</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div>
        <div class="mz-form-row for-radio">
          <div class="mz-input">
            <div>
              <div>
                <input type="radio" name="selected" checked="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mzic-bank-account">
      <div>
        <div class="icon">
          <img src="assets/icons/bank-round-bradesco.png" />
        </div>
        <div class="text">
          <div class="tag">PRINCIPAL</div>
          <div class="title">Bradesco S.A</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div>
        <button
          [disabled]="true"
          class="mz-button only-icon secondary small remove-btn"
          style="min-width: 36px">
          <mzic-svg src="assets/icons/mzic-trash.svg" class="w38" />
        </button>
      </div>
    </div>

    <div class="mzic-bank-account">
      <div>
        <div class="icon">
          <img src="assets/icons/bank-round-bradesco.png" />
        </div>
        <div class="text">
          <div class="title">Bradesco S.A</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div>
        <button
          class="mz-button only-icon secondary small remove-btn"
          style="min-width: 36px">
          <mzic-svg src="assets/icons/mzic-trash.svg" class="w38" />
        </button>
        <div class="mz-form-row for-radio">
          <div class="mz-input">
            <div>
              <div>
                <input type="radio" name="selected" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mzic-bank-account">
      <div>
        <input type="checkbox" mzicCheckbox />
        <div class="icon">
          <img src="assets/icons/bank-round-itau.png" />
        </div>
        <div class="text">
          <div class="title">Itau S.A</div>
          <p>Agência ••44 Conta corrente •••07-4</p>
        </div>
      </div>
      <div>
        <button
          class="mz-button only-icon secondary small remove-btn"
          style="min-width: 36px">
          <mzic-svg src="assets/icons/mzic-trash.svg" class="w38" />
        </button>
      </div>
    </div>
  </div>
</div>
