import { ResponseInterfacePageable } from '../http/response.interface';
import { ApiResponse } from '../shared/api-response.interface';
import { WalletTeamData } from '../team/team.model';

export interface GetWalletTeamData extends ApiResponse {
  data: WalletTeamData[];
}

export interface SearchWalletTeamData extends ResponseInterfacePageable {
  data: {
    content: WalletTeamData[];
  };
}
