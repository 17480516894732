import { CommonModule } from '@angular/common';
import {
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  MzicPopover,
  MzicPopoverService,
  MzicPopoverTemplateConfirmComponent,
  MzicSvgComponent,
} from '@mzic/mzic-components';

@Component({
  selector: 'mzic-popovers-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './popovers.component.html',
  styleUrls: ['./popovers.component.scss'],
})
export class PopoversComponent {
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  @ViewChild('templateTeste') templateTeste!: TemplateRef<any>;

  popoverRef: MzicPopover | undefined;

  constructor(private mzicPopoverService: MzicPopoverService) {}

  openComponent() {
    const dialog = this.mzicPopoverService.openComponent({
      component: MzicPopoverTemplateConfirmComponent,
      dialogConfig: {
        width: '400px',
      },
      data: {
        title: 'Sou um componente',
        description: 'Estou passando as variáveis aqui',
        cancelButton: 'CANCELA TUDO',
        confirmButton: 'CONFIRMA TUDO',
      },
    });

    // Se eu quiser posso fechar o componente por aqui..
    setTimeout(() => {
      console.log('Fechou o componente manualmente');
      dialog.close();
    }, 5000);

    dialog
      .onClosed()
      .subscribe((data) => console.log('Fechou e retornou a data:', data));
  }

  openTemplate() {
    this.popoverRef = this.mzicPopoverService.openComponent({
      component: this.templateTeste,
    });
    this.popoverRef.onClosed().subscribe((data) => {
      console.log('Fechou com:', data);
    });
  }

  closeTemplatePopover(data: boolean) {
    if (this.popoverRef) {
      this.popoverRef.close(data);
    }
  }

  openConfirm() {
    this.mzicPopoverService
      .openConfirm({
        title: 'Confirmar?',
        description: 'Deseja confirmar a ação?',
      })
      .subscribe((data) => {
        console.log('Confirmou?', data);
      });
  }
}
