import { WalletTeamMember } from '../team/team.model';

export interface ActivityLog {
  id: number;
  idActivityLog: string;
  member: WalletTeamMember;
  event: ActivityLogEvent;
  createdAt: string;
}

export interface ActivityLogEvent {
  title: string;
  detail: string;
}
