<h1>Labels</h1>
<div class="components-container">
  <div>
    <label class="text-heading-xxxl {{ color }}">Regular</label>
    <label class="text-heading-xxl {{ color }}">Regular</label>
    <label class="text-heading-xl {{ color }}">Regular</label>
    <label class="text-heading-lg {{ color }}">Regular</label>
    <label class="text-heading-md {{ color }}">Regular</label>
    <label class="text-heading-sm {{ color }}">Regular</label>
    <label class="text-body-lg {{ color }}">Regular</label>
    <label class="text-body-md {{ color }}">Regular</label>
    <label class="text-body-xxsm {{ color }}">Regular</label>
    <label class="text-body-xsm {{ color }}">Regular</label>
    <label class="text-body-sm {{ color }}">Regular</label>
  </div>

  <div>
    <label class="text-heading-xxxl-semibold {{ color }}">Semi</label>
    <label class="text-heading-xxl-semibold {{ color }}">Semi</label>
    <label class="text-heading-xl-semibold {{ color }}">Semi</label>
    <label class="text-heading-lg-semibold {{ color }}">Semi</label>
    <label class="text-heading-md-semibold {{ color }}">Semi</label>
    <label class="text-heading-sm-semibold {{ color }}">Semi</label>
    <label class="text-body-lg-semibold {{ color }}">Semi</label>
    <label class="text-body-md-semibold {{ color }}">Semi</label>
    <label class="text-body-xxsm-semibold {{ color }}">Semi</label>
    <label class="text-body-xsm-semibold {{ color }}">Semi</label>
    <label class="text-body-sm-semibold {{ color }}">Semi</label>
  </div>

  <div>
    <label class="text-heading-xxxl-bold {{ color }}">Bold</label>
    <label class="text-heading-xxl-bold {{ color }}">Bold</label>
    <label class="text-heading-xl-bold {{ color }}">Bold</label>
    <label class="text-heading-lg-bold {{ color }}">Bold</label>
    <label class="text-heading-md-bold {{ color }}">Bold</label>
    <label class="text-body-lg-bold {{ color }}">Bold</label>
    <label class="text-body-md-bold {{ color }}">Bold</label>
    <label class="text-body-xxsm-bold {{ color }}">Bold</label>
    <label class="text-body-xsm-bold {{ color }}">Bold</label>
    <label class="text-body-sm-bold {{ color }}">Bold</label>
  </div>

  <div>
    <label class="text-heading-lg primary">Primary</label>
    <label class="text-heading-lg secondary">Secondary</label>
    <label class="text-heading-lg disabled">Disabled</label>
    <label class="text-heading-lg black">Black</label>
    <label class="text-heading-lg success">Success</label>
    <label class="text-heading-lg danger">Danger</label>
    <label class="text-heading-lg alert">Alert</label>
    <label class="text-heading-lg info">Info</label>
  </div>
</div>
