import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MzicSvgComponent,
  SelectBasicComponent,
  SelectControlComponent,
  SelectSimpleComponent,
  SelectSimpleOptionComponent,
} from '@mzic/mzic-components';
import {
  SelectOption,
  SelectOrientationEnum,
  SelectVariationEnum,
} from '@mzic/mzic-interfaces';

@Component({
  selector: 'mzic-selects-components',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    SelectBasicComponent,
    SelectSimpleComponent,
    SelectSimpleOptionComponent,
    ReactiveFormsModule,
    SelectControlComponent,
    FormsModule,
  ],
  templateUrl: './selects.component.html',
  styleUrls: ['./selects.component.scss'],
})
export class SelectsComponent implements OnInit {
  protected selectOptionsYear: SelectOption[] = [
    { id: 2004, info: { title: '2004' } },
    { id: 2005, info: { title: '2005' } },
    { id: 2006, info: { title: '2006' } },
    { id: 2007, info: { title: '2007' } },
  ];

  protected selectOptionsLanguage: SelectOption[] = [
    { id: 1, info: { title: 'English' } },
    { id: 2, info: { title: 'Portuguese' } },
    { id: 3, info: { title: 'Spanish' } },
    { id: 4, info: { title: 'French' } },
    { id: 5, info: { title: 'German' } },
    { id: 6, info: { title: 'Chinese' } },
    { id: 7, info: { title: 'Japanese' } },
    { id: 8, info: { title: 'Russian' } },
    { id: 9, info: { title: 'Italian' } },
    { id: 10, info: { title: 'Arabic' } },
  ];

  protected selectOptionsSongs: SelectOption[] = [
    { id: 1, info: { title: '$0.69 Single Black' } },
    { id: 2, info: { title: '$0.61 Single Medium' } },
    { id: 3, info: { title: '$7.69 Single' } },
    { id: 4, info: { title: '$22.69 Single Platinum' } },
  ];

  protected selectOptionsArtists: SelectOption[] = [
    {
      id: 1,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Alok',
      },
    },
    {
      id: 2,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Avicii',
      },
    },
    {
      id: 3,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'KVSH',
      },
    },
    {
      id: 4,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Alexo',
      },
    },
    {
      id: 5,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Vintage Culture',
      },
    },
    {
      id: 6,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Beyonce',
      },
    },
    {
      id: 7,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'JAY-Z',
      },
    },
    {
      id: 8,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Alessia Cara',
      },
    },
  ];

  protected selectOptionsAssets: SelectOption[] = [
    {
      id: 1,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Deep down',
        version: '(Radio edit)',
        description: 'Alok x Ella Eyre x Kenny Dope feat. Never Dull',
      },
    },
    {
      id: 2,
      info: {
        image: 'https://picsum.photos/36/36',
        title: 'Because this',
        description: 'Avicii feat Alok',
      },
    },
  ];

  protected selectOptionsRoles: SelectOption[] = [
    { id: 1, info: { title: 'Administrator 1' } },
    { id: 2, info: { title: 'Administrator 2' } },
    { id: 3, info: { title: 'Administrator 3' } },
    { id: 4, info: { title: 'Administrator 4' } },
  ];

  protected selectOptionsMusics: SelectOption[] = [
    { id: 1254, info: { title: 'Fast Car' } },
    { id: 4454, info: { title: 'Mostro Invisível' } },
    { id: 9254, info: { title: 'Céu Azul' } },
    { id: 7254, info: { title: 'Mask Off' } },
  ];

  protected activeSelect = false;
  protected form!: FormGroup;
  protected formRequired!: FormGroup;
  simpleSelectedOption: (string | number)[] | undefined;
  protected optionSelect!: unknown;
  selectVariationEnum = SelectVariationEnum;
  selectOrientationEnum = SelectOrientationEnum;

  simpleSelectOptions1: SelectOption[] = [
    { id: '1', info: { title: 'Brasil' } },
    { id: '2', info: { title: 'EUA' } },
    { id: '3', info: { title: 'Canadá' } },
  ];

  simpleSelectModel1 = '';
  simpleSelectModel1ChangesCount = 0;
  simpleSelectModel2 = '';
  simpleSelectModel2ChangesCount = 0;

  ngOnInit(): void {
    this.form = new FormGroup({
      years: new FormControl(''),
    });

    this.formRequired = new FormGroup({
      music: new FormControl(null, [Validators.required]),
    });
  }

  handleOptionChange(optionsId: (string | number)[] | undefined) {
    this.simpleSelectedOption = optionsId;
  }

  handleSubmit(): void {
    console.log(this.form.value);
  }

  handleSubmitRequired(): void {
    console.log(this.formRequired.value);
  }

  increment1(value: any) {
    this.simpleSelectModel1ChangesCount++;
  }
  increment2(value: any) {
    this.simpleSelectModel2ChangesCount++;
  }
}
