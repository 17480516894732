import { Routes } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { ColorsComponent } from './colors/colors.component';
import { IconsComponent } from './icons/icons.component';
import { InputsComponent } from './inputs/inputs.component';
import { LabelsComponent } from './labels/labels.component';
import { LoadersComponent } from './loaders/loaders.component';
import { PopoversComponent } from './popovers/popovers.component';
import { SelectsComponent } from './selects/selects.component';
import { SideFloatingMenuPageComponent } from './side-floating-menu/side-floating-menu.component';
import { TablesComponent } from './tables/tables.component';
import { TabsComponent } from './tabs/tabs.component';
import { TooltipsComponent } from './tooltips/tooltips.component';

export const routes: Routes = [
  { path: '', redirectTo: 'buttons', pathMatch: 'full' },
  { path: 'buttons', component: ButtonsComponent },
  { path: 'icons', component: IconsComponent },
  { path: 'labels', component: LabelsComponent },
  { path: 'colors', component: ColorsComponent },
  { path: 'inputs', component: InputsComponent },
  { path: 'selects', component: SelectsComponent },
  { path: 'popovers', component: PopoversComponent },
  { path: 'tables', component: TablesComponent },
  { path: 'alerts', component: AlertsComponent },
  { path: 'tabs', component: TabsComponent },
  { path: 'tooltips', component: TooltipsComponent },
  { path: 'bank-accounts', component: BankAccountComponent },
  { path: 'audio-player', component: AudioPlayerComponent },
  { path: 'calendars', component: CalendarsComponent },
  { path: 'loaders', component: LoadersComponent },
  { path: 'side-floating-menu', component: SideFloatingMenuPageComponent },
];
