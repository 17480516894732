export interface WalletTeamResponse {
  timestamp: string;
  status: string;
  data: {
    content: WalletTeamData[];
    pageable: {
      sort: Sort;
      offset: number;
      pageSize: number;
      pageNumber: number;
      paged: boolean;
      unpaged: boolean;
    };
    last: boolean;
    totalPages: number;
    totalElements: number;
    size: number;
    number: number;
    sort: Sort;
    numberOfElements: number;
    first: boolean;
    empty: boolean;
  };
}

interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface WalletTeamData {
  id: number;
  walletId: number;
  artistId: number;
  member: WalletTeamMember;
  team: WalletTeam;
  role: WalletTeamRole;
  active: boolean;
}

export interface WalletTeamMember {
  name: string;
  email: string;
  photo: string;
}

export interface WalletTeam {
  id: number;
  artistId: number;
  name: string;
  partnerUserId?: number;
  enterpriseId?: number;
  registryType: RegistryTypeEnum;
  hash?: string;
  artistIsVerified: boolean;
  artistUuidViberate: string;
}

export interface WalletTeamRole {
  id: number;
  name: string;
}

export enum RegistryTypeEnum {
  ARTIST = 'Artist',
  LABEL = 'Label',
  PARTNER = 'Partner',
}
