import { TrackStatusEnum } from '../distribution/track-resource.interfaces';
import { ApiResponse } from '../shared/api-response.interface';
import { ReleaseStatusEnum } from './enum/release-status.enum';

export interface GetReleaseV2FindReleaseId extends ApiResponse {
  data: GetReleaseV2FindReleaseIdData;
}

export interface GetReleaseV2FindReleaseIdData {
  id: number;
  language: GetReleaseV2FindReleaseIdDataLanguage;
  hash: string;
  title: string;
  titleVersion: string;
  primaryGenre: {
    id: number;
    name: string;
  };
  secondaryGenre: {
    id: number;
    name: string;
  };
  label: {
    id: number;
    imageId: string;
    name: string;
    description: string;
  };
  previouslyReleased: boolean;
  upc: string;
  releaseDate: Date;
  releaseType: string;
  releaseExternalId: number;
  totalDuration: number;
  artistNames: string;
  status: ReleaseStatusEnum;
  artists: GetReleaseV2FindReleaseIdDataArtist[];
  releaseLanguages: [
    {
      title: string;
      language: {
        id: number;
        name: string;
        code: string;
        flagUrl: string;
      };
    },
  ];
  tracks: GetReleaseV2FindReleaseIdDataTrack[];
}

export interface GetReleaseV2FindReleaseIdDataLanguage {
  id: number;
  name: string;
  code: string;
  flagUrl: string;
}

export interface GetReleaseV2FindReleaseIdDataArtist {
  artist: {
    id: number;
    artistExternalId: number;
    name: string;
    photo: string;
    background: string;
    email: string;
    artistAppleId: string;
    artistSpotifyId: string;
    uuidViberate: string;
    fullName: string;
  };
  artistType: {
    id: number;
    roleId: number;
    name: string;
    priority: number;
  };
  mainArtist: boolean;
}

export interface GetReleaseV2FindReleaseIdDataTrack {
  id: number;
  title: string;
  titleVersion: string;
  trackExternalId: number;
  primaryGenre: {
    id: number;
    name: string;
  };
  secondaryGenre: {
    id: number;
    name: string;
  };
  isrc: string;
  explicit: boolean;
  lyricsLanguageId: number;
  lyrics: string;
  trackTypeId: number;
  instrumental: boolean;
  startPreview: number;
  artists: GetReleaseV2FindReleaseIdDataTrackArtist[];
  writers: GetReleaseV2FindReleaseIdDataTrackWriter[];
  languages: [
    {
      title: string;
      language: {
        id: number;
        name: string;
        code: string;
        flagUrl: string;
      };
    },
  ];
  filePathLocation: string;
  hash: string;
  externalFileHash: string;
  duration: number;
  status: TrackStatusEnum;
  artistNames: string;
}

export interface GetReleaseV2FindReleaseIdDataTrackWriter {
  artist: {
    id: number;
    name: string;
    lastName: string;
    socialSecurity: string;
    phoneNumber: string;
    birthDate: Date;
    photo: string;
    background: string;
    email: string;
    bio: string;
    nationality: string;
    genreId: number;
    uuidViberate: string;
    artistAppleId: string;
    artistSpotifyId: string;
    artistExternalId: number;
  };
  artistType: {
    id: number;
    roleId: number;
    name: string;
    priority: number;
  };
  mainArtist: true;
}

export interface GetReleaseV2FindReleaseIdDataTrackArtist {
  artist: {
    id: number;
    name: string;
    lastName: string;
    socialSecurity: string;
    phoneNumber: string;
    birthDate: Date;
    photo: string;
    background: string;
    email: string;
    bio: string;
    nationality: string;
    genreId: number;
    uuidViberate: string;
    artistAppleId: string;
    artistSpotifyId: string;
    artistExternalId: number;
  };
  artistType: {
    id: number;
    roleId: number;
    name: string;
    priority: number;
  };
  mainArtist: boolean;
}
