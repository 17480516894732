import { ReleaseDetailArtistListItem } from "./release-detail-artist-list-item.interface";
import { ReleaseDetailLocal } from "./release-detail-local.interface";
import { ReleaseDetailTrack } from "./release-detail-track.interface";

export interface ReleaseDetail {
  releaseId: number;
  image: string;
  status: string;
  name: string;
  version: string;
  artistList: ReleaseDetailArtistListItem[];
  primaryMusicStyleId: number;
  secondaryMusicStyleId: number;
  releaseDate: Date;
  totalTracks: number;
  totalTracksLength: number;
  languageId: number;
  releaseLocals: ReleaseDetailLocal[];
  releaseTypeId: number;
  tracks: ReleaseDetailTrack[];
  labelId: number;
  labelName: string;
  upc: string;
}
