import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MzicSvgComponent } from '@mzic/mzic-components';

type ViewMode =
  | 'DEFAULT'
  | 'PURPLE'
  | 'BUTTON'
  | 'BUTTON_WHITE'
  | 'BUTTON_OUTLINE'
  | 'BUTTON_DISABLED'
  | 'IMG';

@Component({
  selector: 'mzic-icons-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
})
export class IconsComponent {
  numbers: number[] = [];

  activeViewMode: ViewMode = 'DEFAULT';

  showWidths = false;
  showColors = false;

  icons = [
    { filename: 'mzic-plus.svg', stroke: false, tags: [] },
    { filename: 'mzic-check-mark.svg', stroke: true, tags: [] },
    { filename: 'mzic-check-mark-filled.svg', stroke: false, tags: [] },
    { filename: 'mzic-close-fill.svg', stroke: false, tags: [] },
    { filename: 'mzic-reload.svg', stroke: false, tags: [] },
    { filename: 'mzic-play.svg', stroke: false, tags: [] },
    { filename: 'mzic-pause.svg', stroke: false, tags: [] },
    { filename: 'mzic-trash.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-down.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-up.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-up-filled.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-left.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-right.svg', stroke: false, tags: [] },
    { filename: 'mzic-edit.svg', stroke: false, tags: [] },
    { filename: 'mzic-link.svg', stroke: false, tags: [] },
    { filename: 'mzic-download.svg', stroke: true, tags: [] },
    { filename: 'mzic-bullets.svg', stroke: false, tags: [] },
    { filename: 'mzic-bullets-horizontal.svg', stroke: false, tags: [] },
    { filename: 'mzic-search.svg', stroke: false, tags: [] },
    { filename: 'mzic-message.svg', stroke: true, tags: [] },
    { filename: 'mzic-pictures.svg', stroke: false, tags: [] },
    { filename: 'mzic-uploader.svg', stroke: false, tags: [] },
    { filename: 'mzic-claim.svg', stroke: true, tags: [] },
    { filename: 'mzic-camera.svg', stroke: false, tags: [] },
    { filename: 'mzic-close.svg', stroke: false, tags: [] },
    { filename: 'mzic-eye.svg', stroke: false, tags: [] },
    { filename: 'mzic-eye-filled.svg', stroke: false, tags: [] },
    { filename: 'mzic-eye-filled-hidden.svg', stroke: false, tags: [] },
    { filename: 'mzic-move.svg', stroke: false, tags: [] },
    { filename: 'mzic-move-horizontal.svg', stroke: false, tags: [] },
    { filename: 'mzic-close-filled.svg', stroke: false, tags: [] },
    { filename: 'mzic-dash-filled.svg', stroke: false, tags: [] },
    { filename: 'mzic-exclamation.svg', stroke: false, tags: [] },
    { filename: 'mzic-calendar.svg', stroke: false, tags: [] },
    { filename: 'mzic-copy.svg', stroke: true, tags: [] },
    { filename: 'mzic-music.svg', stroke: false, tags: [] },
    { filename: 'mzic-question.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-full-right.svg', stroke: false, tags: [] },
    { filename: 'mzic-filter.svg', stroke: false, tags: [] },
    { filename: 'mzic-expand.svg', stroke: false, tags: [] },
    { filename: 'mzic-clock.svg', stroke: false, tags: [] },
    { filename: 'mzic-arrow-filled-right-down.svg', stroke: false, tags: [] },
    { filename: 'mzic-circle-arrow-right-down.svg', stroke: false, tags: [] },
    { filename: 'mzic-document.svg', stroke: false, tags: [] },
    { filename: 'mzic-finance.svg', stroke: false, tags: [] },
    { filename: 'mzic-money-convert.svg', stroke: true, tags: [] },
    { filename: 'mzic-wallet-right.svg', stroke: false, tags: [] },
    { filename: 'mzic-bank.svg', stroke: false, tags: [] },
    { filename: 'mzic-lock.svg', stroke: false, tags: [] },
    { filename: 'mzic-settings.svg', stroke: false, tags: [] },
    { filename: 'mzic-moon.svg', stroke: true, tags: [] },
    { filename: 'mzic-language.svg', stroke: true, tags: [] },
    { filename: 'mzic-language-v2.svg', stroke: false, tags: [] },
    { filename: 'mzic-sign-out.svg', stroke: false, tags: [] },
    { filename: 'mzic-pencil.svg', stroke: false, tags: [] },
    { filename: 'mzic-microphone.svg', stroke: false, tags: [] },
    { filename: 'mzic-globe.svg', stroke: false, tags: [] },
    { filename: 'mzic-lyrics-2.svg', stroke: false, tags: [] },
    { filename: 'empty-song.svg', stroke: false, tags: [] },
    { filename: 'empty-song-square.svg', stroke: false, tags: [] },
    { filename: 'mzic-forbidden.svg', stroke: false, tags: [] },
    { filename: 'song-rounded-green.svg', stroke: false, tags: [] },
    { filename: 'song-rounded-yellow.svg', stroke: false, tags: [] },
    { filename: 'song-rounded-red.svg', stroke: false, tags: [] },
    { filename: 'mzic-starts.svg', stroke: false, tags: [] },
    { filename: 'mzic-disc.svg', stroke: false, tags: [] },
    { filename: 'mzic-money-tag.svg', stroke: false, tags: [] },
    { filename: 'mzic-pie-chart.svg', stroke: false, tags: [] },
    { filename: 'mzic-loading.svg', stroke: false, tags: [] },
    { filename: 'mzic-comment.svg', stroke: false, tags: [] },
    { filename: 'mzic-playlist.svg', stroke: true, tags: [] },
    { filename: 'mzic-external-link.svg', stroke: false, tags: [] },

    {
      filename: 'mzic-music-circle.svg',
      stroke: false,
      circle: true,
      tags: [],
    },
    {
      filename: 'mzic-bag.svg',
      stroke: false,
      tags: [],
    },
  ];

  constructor() {
    for (let index = 1; index <= 96; index++) {
      this.numbers.push(index);
    }
  }

  async copyIconPath(iconName: string) {
    try {
      await navigator.clipboard.writeText(`assets/icons/${iconName}`);
    } catch (err) {
      alert('Error when trying to copy icon path');
    }
  }
}
