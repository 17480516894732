import { MzicPopoverPosition } from './mzic-popover-position.type';

export interface MzicPopoverOptions {
  position?: MzicPopoverPosition;
  target?: HTMLElement;
  gapX?: number;
  gapY?: number;
  cssClass?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  showBackground?: boolean;
  closeOnClickOutside?: boolean;
  showOutside?: boolean;
  fixed?: boolean;
}
