import { ReleaseTypeEnum } from "./enum/release-type.enum";

export interface ReleasesListItem {
  upc: string;
  isrc: string;
  trackISRC: string;
  artistId: number;
  labelName: string;
  version: string;
  releaseDate: Date;
  creationDate: Date;
  warningsCount: number;
  smartContractStatus: string;
  releaseId: number;
  name: string;
  releaseTypeId: ReleaseTypeEnum;
  isLockedForDistribution: boolean;
  isIngested: boolean;
  enterpriseId: number;
  image: string;
}
