import { Release } from "../releases/release.interface";
import { Wav } from "../wav/wav.interface";

export interface TracksListItem {
  trackId: number;
  name: string;
  artistId: number;
  artistName: string;
  trackLength: number;
  trackVendorId: null;
  isrc: null;
  description: null;
  languageId: number;
  explicit: boolean;
  version: string;
  posterImageId: null;
  wavFileId: string;
  flacFileId: null;
  image: null;
  wav: Wav;
  flac: null;
  primaryMusicStyleId: number | null;
  secondaryMusicStyleId: number | null;
  trackType: number;
  licenseRequestStatus: null;
  isAudioValid: null;
  catalog: null;
  isFullyLocked: boolean;
  enterpriseId: number;
  releases: Release[];
  monetizations: null;
  royaltyToken: null;
}
