export interface ResponseResetPassword {
  timestamp: string;
  status: string;
  data: {
    expiration: string;
    token: string;
    refreshToken: string;
    wallet: string;
    userId: number;
    username: string;
    email: string;
    requireMfa: boolean;
    artistId: number;
  };
}
