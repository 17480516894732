<h1>Tooltips</h1>
<div class="components-container">
  <div class="doc-component-code-container">
    <div class="component-container">
      <mzic-tooltip [content]="content1" />
    </div>
    <div class="code-container">
      <h2>Com Ícone</h2>
      <p>Uso do tooltip com o ícone padrão</p>
      <code class="code">
        &lt;mzic-tooltip content="uma string aqui.." /&gt;
      </code>
    </div>
  </div>

  <div class="doc-component-code-container">
    <div class="component-container">
      <button
        class="mz-button medium"
        mzicTooltip="I'm a tooltip!"
        mzicTooltipEvent="click">
        Button on Click
      </button>
    </div>
    <div class="code-container">
      <h2>Evento de Click</h2>
      <p>Tooltip direto no botão com evento de click</p>
      <code class="code">
        &lt;button class="mz-button medium" mzicTooltip="I'm a tooltip!"
        mzicTooltipEvent="click"> Button &lt;/button/&gt;
      </code>
    </div>
  </div>

  <div class="doc-component-code-container">
    <div class="component-container">
      <span mzicTooltip="Hi">small</span>
      <span
        mzicTooltip="I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular">
        medium
      </span>
      <span
        mzicTooltip="I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular">
        large
      </span>
      <span
        mzicTooltip="I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular I'm a big tooltip with a big text, and this is how I look like in a webpage with Angular">
        xxlarge
      </span>
      <span [mzicTooltip]="htmlContent">With HTML</span>
    </div>
    <div class="code-container">
      <h2>Content Length</h2>
      <p>diferentes tamanhos de conteudo</p>
    </div>
  </div>

  <div class="doc-component-code-container">
    <div class="component-container">
      <button
        class="mz-button medium"
        mzicTooltip="I'm a tooltip!"
        mzicTooltipEvent="hover">
        Button on Hover
      </button>
    </div>
    <div class="code-container">
      <h2>Button (Hover)</h2>
      <p>Tooltip direto no botão com evento de hover</p>
      <code class="code">
        &lt;button class="mz-button medium" mzicTooltip="I'm a tooltip!"> Button
        &lt;/button/&gt;
      </code>
    </div>
  </div>

  <div class="doc-component-code-container">
    <div class="component-container">
      <mzic-svg
        src="assets/icons/mzic-trash.svg"
        mzicTooltip="I'm a tooltip!" />
    </div>
    <div class="code-container">
      <h2>Icon</h2>
      <p>Tooltip direto no ícone</p>
      <code class="code">
        &lt;mzic-svg src="assets/icons/mzic-trash.svg" mzicTooltip="I'm a
        tooltip!" /&gt;
      </code>
    </div>
  </div>

  <div class="doc-component-code-container">
    <div class="component-container">
      <!-- @@@ inicio do conteudo -->
      <div class="mz-form-row vertical for-text icon-right-1 icon-left-1">
        <div class="mz-input">
          <div class="mz-input-container">
            <input type="text" value="I'm a input with a icon with a tooltip" />
            <div class="icon left">
              <mzic-svg
                src="assets/icons/mzic-search.svg"
                mzicTooltip="I'm a tooltip" />
            </div>
            <div class="icon right">
              <mzic-svg
                src="assets/icons/mzic-question.svg"
                mzicTooltip="I'm a tooltip" />
            </div>
          </div>
        </div>
      </div>
      <!-- @@@ fim do conteudo -->
    </div>
    <div class="code-container">
      <h2>Input with icons</h2>
      <p>Tooltip nos ícones dentro do input</p>
      <pre class="code">
        <code>
&lt;div class="mz-form-row vertical for-text icon-right-1 icon-left-1"&gt;
  &lt;div class="mz-input"&gt;
    &lt;div class="mz-input-container"&gt;
      &lt;input type="text" value="I'm a input with a icon with a tooltip" /&gt;
      &lt;div class="icon left"&gt;
        &lt;mzic-svg
          src="assets/icons/mzic-search.svg"
          mzicTooltip="I'm a tooltip" /&gt;
      &lt;/div&gt;
      &lt;div class="icon right"&gt;
        &lt;mzic-svg
          src="assets/icons/mzic-question.svg"
          mzicTooltip="I'm a tooltip" /&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
        </code>
      </pre>
    </div>
  </div>

  <div class="doc-component-code-container">
    <div class="component-container">
      <img
        src="assets/images/mocks/taylor-selection-artist.png"
        width="200"
        mzicTooltip="I'm a tooltip " />
    </div>
    <div class="code-container">
      <h2>Image</h2>
      <p>Tooltip direto na imagem</p>
      <code class="code">
        &lt;img src="assets/images/mocks/taylor-selection-artist.png"
        width="200" mzicTooltip="I'm a tooltip " /&gt;
      </code>
    </div>
  </div>
  <div class="doc-component-code-container">
    <div class="component-container">
      <h3 mzicTooltip="I'm a tooltip">I'm a title</h3>
    </div>
    <div class="code-container">
      <h2>Title</h2>
      <p>Tooltip no título</p>
      <code class="code">
        &lt;h3 mzicTooltip="I'm a tooltip"&gt;I'm a title&lt;/h3&gt;
      </code>
    </div>
  </div>
</div>

<br />
<br />
<br />

<header>
  <h2>Explain</h2>
  <p>É um componente mais simples que o tooltip, já vem com ícone embutido</p>
  <code>&lt;mzic-explain [text]="'Aqui você coloca o texto'" /&gt;</code>
</header>

<div class="container">
  <div class="row mt-5">
    <div class="col-4">
      <h3>Uso simples</h3>
      <hr />
      <div class="col-4">
        <mzic-explain [text]="'Aqui você coloca o texto'" />
      </div>
    </div>
    <div class="col-4">
      <h4>Com um botão</h4>
      <hr />
      <div class="col-1">
        <button class="mz-button small">
          Baixar
          <mzic-explain
            [text]="'Clique aqui para baixar o arquivo que deseja'" />
        </button>
      </div>
    </div>
    <div class="col-4">
      <h4>Cor e tamanho</h4>
      <hr />
      <div class="col-1">
        <button class="mz-button small">
          Baixar
          <mzic-explain
            iconSize="w20"
            iconClass="purple-100"
            [text]="'Clique aqui para baixar o arquivo que deseja'" />
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-4">
      <h4>Cor e tamanho</h4>
      <hr />
      <div class="col-1">
        <button class="mz-button small">
          Baixar
          <mzic-explain
            iconSize="w20"
            iconClass="purple-100"
            [text]="
              'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dignissimos, ratione quisquam nobis saepe nulla praesentium aut libero obcaecati qui iure omnis quidem tenetur incidunt, architecto maxime, voluptate temporibus ea ipsa. Eum, obcaecati impedit numquam nemo assumenda aliquam rem illo animi dolores tempora architecto alias maxime, eius est dicta voluptatibus ratione earum enim quos ullam natus libero quasi magnam? Reiciendis, possimus.'
            " />
        </button>
      </div>
    </div>
  </div>
</div>

<!-- todo: implementar com diretiva -->
