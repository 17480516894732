export interface ReleaseDetailTrackArtist {
  contributorId: string;
  name: string;
  image: string;
  artistId: number;
  roleId: number;
  artistAppleId: string;
  artistSpotifyId: string;
  isMainArtist: boolean;
}
