export interface PostReleaseV2DistributionReleaseIdBody {
  stores: PostReleaseV2DistributionReleaseIdBodyStore[];
  releaseDate?: string;
  timezone?: PostReleaseV2DistributionReleaseIdBodyTimezone;
  country?: PostReleaseV2DistributionReleaseIdBodyCountry;
}

export interface PostReleaseV2DistributionReleaseIdBodyTimezone {
  id: number;
  time: string;
}

export interface PostReleaseV2DistributionReleaseIdBodyStore {
  id: number;
  tierPriceReleaseId?: number;
  tierPriceTrackId?: number;
  monetizationPolicyId?: number;
}

export interface PostReleaseV2DistributionReleaseIdBodyCountry {
  ids: number[];
  type: string; // 'WHITELIST';
}
