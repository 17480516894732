import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  ExplainComponent,
  MzicSvgComponent,
  MzicTooltipComponent,
} from '@mzic/mzic-components';
import { MzicTooltipDirective } from '@mzic/mzic-directives';

@Component({
  selector: 'mzic-tooltips-components',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    MzicTooltipComponent,
    MzicTooltipDirective,
    ExplainComponent,
  ],
  templateUrl: './tooltips.component.html',
  styleUrls: ['./tooltips.component.scss'],
})
export class TooltipsComponent {
  isOpen = false;

  content1 = `<strong>Policy descriptions:</strong>
              <p>When ‘Monetize in all countries’ is set, you will receive royalties as tracks are used/played.</p>
              <p>With ‘Track in all countries’ set, you will receive analytics on the usage/plays of your tracks.</p>`;

  htmlContent = `<h3 style="margin: 0;">Im a title</h3> <p>Exercitation incididunt irure tempor dolor reprehenderit quis in. Nisi fugiat elit et eu exercitation. <br> <p>Exercitation incididunt irure tempor dolor reprehenderit quis in. Nisi fugiat elit et eu exercitation. </p> <a href="#" style="font-size: 16px;">I'm a link</a>  `;
}
