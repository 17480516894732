import { Wav } from "../wav/wav.interface";

export interface Release {
  releaseId: number;
  name: string;
  version: null;
  releaseTypeId: number;
  upc: null;
  isLockedForDistribution: boolean;
  isIngested: null;
  enterpriseId: number;
  image: Wav;
}
