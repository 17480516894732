<h1>Alerts</h1>
<div class="components-container">
  <div>
    <div class="mzic-alert-container">
      <div class="mzic-alert error">
        <mzic-svg src="assets/icons/mzic-close-filled.svg" />
        <p>
          The artist 'Calvin Harris' is on a Spotify watch list. Please email
          support to confirm that you have the rights to distribute material
          with this artist.
        </p>
      </div>
      <div class="mzic-alert error filled">
        <mzic-svg src="assets/icons/mzic-close-filled.svg" />
        <p>
          A release must be distributed to YouTube Music if it is being
          distributed to YouTube Content ID.
        </p>
      </div>
      <div class="mzic-alert warning">
        <mzic-svg src="assets/icons/mzic-exclamation-filled.svg" />
        <p>
          You have 'duplicated' audio files in your release. This means that one
          or more tracks have identical audio files which could lead into
          distribution issues.
        </p>
      </div>
      <div class="mzic-alert warning filled">
        <mzic-svg src="assets/icons/mzic-exclamation-filled.svg" />
        <p>
          A release must be distributed to YouTube Music if it is being
          distributed to YouTube Content ID.
        </p>
      </div>
    </div>
  </div>
</div>
