import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import {
  MzicCheckboxDirective,
  MzicSkeletonComponent,
  MzicSvgComponent,
  MzicTablePageSizeComponent,
  MzicTablePaginationComponent,
  MzicTableSortComponent,
  MzicTooltipComponent,
  MzicTrackListItemComponent,
} from '@mzic/mzic-components';
import { MzicDurationPipe } from '@mzic/mzic-utils';

@Component({
  selector: 'mzic-tables-components',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    MzicTooltipComponent,
    MzicTableSortComponent,
    MzicTablePaginationComponent,
    MzicTablePageSizeComponent,
    MzicSkeletonComponent,
    SortablejsModule,
    MzicTrackListItemComponent,
    MzicDurationPipe,
    MzicCheckboxDirective,
  ],
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
})
export class TablesComponent {
  disableSort = true;

  items1 = [
    {
      id: 1,
      isPlaying: false,
      tagText: 'FIX ISSUES',
      tagClass: 'bg-red-500',
      tagIcon: '',
      title: 'Deep Down (feat. Never Dull)',
      description: '',
      image:
        'https://lastfm.freetls.fastly.net/i/u/300x300/fa8f030b4679fc9a3962f545cf69c6f0.jpg',
      isrc: '131321312312',
      duration: 320,
    },
    {
      id: 2,
      isPlaying: true,
      tagText: 'SUCCESS',
      tagClass: 'bg-green-500',
      tagIcon: '',
      title: 'LUA',
      description: '',
      image:
        'https://akamai.sscdn.co/uploadfile/letras/albuns/3/e/7/a/1227201642016441.jpg',
      isrc: '3323123213',
      duration: 180,
    },
    {
      id: 3,
      isPlaying: false,
      tagText: 'DOUBLED',
      tagClass: 'bg-yellow-500',
      tagIcon: '',
      title: 'Jungle',
      description: '',
      image: 'https://i.scdn.co/image/ab67616d00001e02520bb9103221369c9b6abb61',
      isrc: '32312321321',
      duration: 214,
    },
    {
      id: 4,
      isPlaying: false,
      tagText: 'FAILED',
      tagClass: 'red-500',
      tagIcon: `assets/icons/mzic-close-filled.svg`,
      title: "LET'S GET FKD UP",
      description: 'feat. Justin Bieber',
      image:
        'https://akamai.sscdn.co/uploadfile/letras/albuns/c/5/8/9/1101471624618348.jpg',
      isrc: '32312321321',
      duration: 182,
    },
    {
      id: 5,
      isPlaying: false,
      tagText: 'TRY AGAIN',
      tagClass: 'purple-500',
      tagIcon: '',
      title: "LET'S GET FKD UP",
      description: 'feat. Justin Bieber',
      image:
        'https://akamai.sscdn.co/uploadfile/letras/albuns/c/5/8/9/1101471624618348.jpg',
      isrc: '32312321321',
      duration: 182,
    },
    {
      id: 6,
      isPlaying: false,
      tagText: 'MAR 31, 2024',
      tagClass: 'bg-green-500',
      tagIcon: '',
      title: "LET'S GET FKD UP",
      description: 'feat. Justin Bieber',
      image:
        'https://akamai.sscdn.co/uploadfile/letras/albuns/c/5/8/9/1101471624618348.jpg',
      isrc: '32312321321',
      duration: 182,
    },
    {
      id: 7,
      isPlaying: false,
      tagText: 'MAR 31, 2024',
      tagClass: 'green-500',
      tagIcon: 'assets/icons/mzic-calendar.svg',
      title: "LET'S GET FKD UP",
      description: 'feat. Justin Bieber',
      image:
        'https://akamai.sscdn.co/uploadfile/letras/albuns/c/5/8/9/1101471624618348.jpg',
      isrc: '32312321321',
      duration: 182,
    },
  ];

  services = [
    {
      id: 1,
      name: 'Amazon',
      image: 'assets/icons/dsps/24x24/amazon.png',
      statusIcon: 'assets/icons/mzic-dash-filled.svg',
      statusColor: 'neutral-400',
      questionIcon: '',
    },
    {
      id: 2,
      name: 'Anghami',
      image: 'assets/icons/dsps/24x24/anghami.png',
      statusIcon: 'assets/icons/mzic-check-mark-filled.svg',
      statusColor: 'green-400',
      questionIcon: '',
    },
    {
      id: 3,
      name: 'Audiomack',
      image: 'assets/icons/dsps/24x24/audiomack.png',
      statusIcon: 'assets/icons/mzic-arrow-up-filled.svg',
      statusColor: 'yellow-500',
      questionIcon: 'assets/icons/mzic-question.svg',
    },
    {
      id: 4,
      name: 'AWA',
      image: 'assets/icons/dsps/24x24/awa.png',
      statusIcon: 'assets/icons/mzic-close-filled.svg',
      statusColor: 'red-500',
      questionIcon: '',
    },
  ];
}
