import { ApiResponse } from '../shared/api-response.interface';

export interface PostUserImagesUpload extends ApiResponse {
  data: PostUserImagesUploadData;
}

export type PostUserImagesUploadBody = PostUserImagesUploadData;

export interface PostUserImagesUploadData {
  userPhotoType: 'PROFILE_PHOTO' | 'BACKGROUND_PHOTO_PROFILE';
}
