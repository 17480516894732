<main class="components-dashboard">
  <section style="width: 100%">
    <router-outlet></router-outlet>
  </section>
</main>

<ul class="fixed-nav">
  @for (item of menu; track $index) {
    <li>
      <a [routerLink]="[item.path]">{{ item.label }}</a>
    </li>
  }
</ul>
