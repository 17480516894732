import { ApiResponse } from '../shared/api-response.interface';
import { ReleaseDistributionStatusEnum } from './enum/release-distribution-status.enum';

export interface GetReleaseV2DistributionReleaseIdStores extends ApiResponse {
  data: GetReleaseV2DistributionReleaseIdStoresData[];
}

export interface GetReleaseV2DistributionReleaseIdStoresData {
  store: GetReleaseV2DistributionReleaseIdStoresDataStore;
  status: ReleaseDistributionStatusEnum;
  isLive: boolean;
  enqueuedOn: Date;
  deliveredOn: Date;
  urlInStore: string;
  notEligibleForDistribution?: [
    {
      type: string;
      reason: string;
    },
  ];
}

export interface GetReleaseV2DistributionReleaseIdStoresDataStore {
  id: number;
  iconUrl: string;
  name: string;
  active: boolean;
  tierPriceSale: {
    lsTierPriceRelease: [
      {
        id: number;
        value: number;
        name: string;
      },
    ];
    lsTierPriceTrack: [
      {
        id: number;
        value: number;
        name: string;
      },
    ];
  };
  lsMonetizationPolicy: [
    {
      id: number;
      name: string;
    },
  ];
  dependencyStore: {
    id: number;
    tierPriceReleaseId: number;
    tierPriceTrackId: number;
    monetizationPolicyId: number;
  };
  lsToolTip: [
    {
      type: string;
      title: string;
      text: string;
    },
  ];
}
