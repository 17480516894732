import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MzicSvgComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-alerts-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {}
