import { ReleaseStatusListItem } from "./release-status-list-item.interface";
import { ReleaseStatusListStoreNeverDistributed } from "./release-status-list-store-never-distributed.interface";

export interface ReleaseStatusList {
  totalItemsCount: number;
  pageNumber: number;
  pageSize: number;
  items: ReleaseStatusListItem[];
  additionalCounters: {
    album: number;
    ep: number;
    ringtone: number;
    single: number;
    video: number;
  };
  allCount: number;
  neverDistributedCount: number;
  processingCount: number;
  distributedCount: number;
  issuesCount: number;
  takenDownCount: number;
  storesNeverDistributed: ReleaseStatusListStoreNeverDistributed[];
}
