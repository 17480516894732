export interface ApiResponse {
  timestamp: Date;
  status: string;
  data: any;
  error: {
    error: string;
    title: string;
    code: string;
    trace: string;
    metadata: {
      additionalProp1: any;
      additionalProp2: any;
      additionalProp3: any;
    };
    modal: boolean;
  };
  errors: [
    {
      error: string;
      title: string;
      code: string;
      trace: string;
      metadata: {
        additionalProp1: any;
        additionalProp2: any;
        additionalProp3: any;
      };
      modal: boolean;
    },
  ];
  page: number;
  metadata: {
    additionalProp1: any;
    additionalProp2: any;
    additionalProp3: any;
  };
  translations: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  total_pages: number;
  per_page: number;
  total_items: number;
}
