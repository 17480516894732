import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mzic-labels-component',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
})
export class LabelsComponent implements OnInit {
  @Input() index!: number;
  color = '';

  ngOnInit(): void {
    if (this.index && this.index === 1) {
      this.color = 'black';
    }
  }
}
