import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { MzicHttpInterceptorInterceptor } from '@mzic/http-interceptor';
import { MzicSvgComponent } from '@mzic/mzic-components';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { TranslocoRootModule } from './transloco-root.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    SortablejsModule.forRoot({ animation: 150 }),
    TranslocoRootModule,
    MzicSvgComponent,
    OverlayModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MzicHttpInterceptorInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
