<h1>Audio Players</h1>
<div class="components-container">
  <div>
    <small>HTML5 Audio Player</small>
    <audio controls [src]="localAudioFile"></audio>
  </div>
  <div>
    <small>HTML5 Audio Player (HSL)</small>
    <audio controls #audioPlayer></audio>
  </div>
  <div>
    <small>Hidden Player with Audio Player Service</small>
    <div class="hls-btn-container">
      <mzic-svg
        src="assets/icons/mzic-play.svg"
        (click)="playHls()"
        class="purple w42"
        *ngIf="!audioHlsIsPlaying" />
      <mzic-svg
        src="assets/icons/mzic-pause.svg"
        (click)="pauseHls()"
        class="purple w42"
        *ngIf="audioHlsIsPlaying" />
    </div>
  </div>
  <div>
    <small>Audio Player</small>
    <div class="mzic-audio-player-container">
      <div class="wave-container">
        <mzic-audio-player
          [showStartPauseButton]="true"
          [height]="65"
          [showPreviewButton]="true"
          [ctrl]="audioPlayerCtrl1" />
      </div>
    </div>
    <div class="mzic-audio-player-container">
      <small>Audio Player with duration</small>
      <div class="wave-container">
        <mzic-audio-player
          [showStartPauseButton]="true"
          [height]="65"
          [showDuration]="true"
          [ctrl]="audioPlayerCtrl2" />
      </div>
    </div>
    <div class="mzic-audio-player-container">
      <small>Audio Player without preview</small>
      <div class="wave-container">
        <mzic-audio-player
          [showStartPauseButton]="true"
          [height]="65"
          [ctrl]="audioPlayerCtrl3" />
      </div>
    </div>
  </div>
</div>
