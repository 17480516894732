import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MzicSvgComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-bank-account-components',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './bank-account.component.html',
})
export class BankAccountComponent {}
