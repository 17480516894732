import { ResponseInterfacePageable } from '../http/response.interface';

export interface LabelResourceData extends ResponseInterfacePageable {
  content: LabelResource[];
}

export interface LabelResource {
  id?: number;
  imageId?: string; // hash da imagem
  name: string;
  description?: string;
}
