<ng-container *transloco="let t">
  <section
    class="spotify-hero"
    [class.spotify-hero--full]="fullWidth"
    [class.spotify-hero--small-height]="smallHeight"
    [ngStyle]="{ 'background-image': 'url(' + imageUri + ')' }">
    <div class="spotify-hero__content">
      <header class="spotify-hero__header">
        <mzic-spotify-avatar
          altImage="MZIC artist spotify image"
          [imageUri]="imageUri"
          stampUri="assets/icons/mzic-spotify-icon.svg" />

        <div class="spotify-hero__artist__name">
          <div class="spotify-hero__artist__verified">
            <img
              alt="MZIC verified artist icon"
              src="/assets/icons/mzic-artist-verified.svg" />
            <span>{{ t('lang.verified_artist') }}</span>
          </div>
          <p>{{ artistName }}</p>
        </div>
      </header>

      <aside class="spotify-hero__followers-and-listeners">
        <div class="spotify-hero__followers-and-listeners__box">
          <p>{{ listeners === null ? '--' : (listeners | number) }}</p>
          <span>{{ t('lang.monthly_listeners') }}</span>
        </div>
        <div class="spotify-hero__followers-and-listeners__box">
          <p>{{ followers | number }}</p>
          <span>{{ t('lang.followers') }}</span>
        </div>
      </aside>
    </div>
  </section>
</ng-container>
