import { ApiResponse } from '../shared/api-response.interface';

export interface GetTimezone extends ApiResponse {
  data: GetTimezoneData[];
}

export interface GetTimezoneData {
  id: number;
  name: string;
}
