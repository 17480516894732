export interface ReleaseStatusListItem {
  releaseId: number;
  distributorStoreId: number;
  distributorUrlIcon: string;
  albumsCount: number;
  singlesCount: number;
  epCount: number;
  ringtonesCount: number;
  videosCount: number;
  totalQuantity: number;
  totalNet: number;
  releasePriceId: string;
  trackPriceId: string;
  releaseStatus: {
    internalReleaseState: {
      cod: number;
      description: string;
    };
    status: number;
    statusText: string;
    errorMessage: string;
    addedDate: Date;
    deliveryDate: string;
    firstDeliveryDate: string;
    dateLive: string;
    urlInStore: string;
    summarySection: number;
    isError: boolean;
  };
}
