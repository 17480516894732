<h1>Buttons</h1>
<div class="components-container">
  <div>
    <button class="mz-button">Button</button>
    <button class="mz-button medium">Medium</button>
    <button class="mz-button small">Small</button>
    <button class="mz-button mini">Mini</button>
  </div>

  <div>
    <button class="mz-button white">White</button>
    <button class="mz-button white medium">Medium</button>
    <button class="mz-button white small">Small</button>
    <button class="mz-button white mini">Mini</button>
  </div>

  <div>
    <button disabled class="mz-button">Disabled</button>
    <button [disabled]="true" class="mz-button medium">Medium</button>
    <button disabled class="mz-button small">Small</button>
    <button disabled class="mz-button mini">Mini</button>
  </div>

  <div>
    <button class="mz-button">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button class="mz-button medium">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button class="mz-button small">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button class="mz-button mini">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>

  <div>
    <button class="mz-button">
      <mzic-spinner />
      Spinner
    </button>
    <button class="mz-button medium">
      <mzic-spinner />
      Spinner
    </button>
    <button class="mz-button small">
      <mzic-spinner />
      Spinner
    </button>
    <button class="mz-button mini">
      <mzic-spinner />
      Spinner
    </button>
  </div>

  <div>
    <button class="mz-button white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button class="mz-button medium white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button class="mz-button small white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button class="mz-button mini white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>

  <div>
    <button class="mz-button white">
      <mzic-spinner />
      Spinner
    </button>
    <button class="mz-button medium white">
      <mzic-spinner />
      Spinner
    </button>
    <button class="mz-button small white">
      <mzic-spinner />
      Spinner
    </button>
    <button class="mz-button mini white">
      <mzic-spinner />
      Spinner
    </button>
  </div>

  <div>
    <button disabled class="mz-button">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button disabled class="mz-button medium">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button disabled class="mz-button small">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button disabled class="mz-button mini">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>

  <div>
    <button disabled class="mz-button">
      <mzic-spinner />
      Spinner
    </button>
    <button disabled class="mz-button medium">
      <mzic-spinner />
      Spinner
    </button>
    <button disabled class="mz-button small">
      <mzic-spinner />
      Spinner
    </button>
    <button disabled class="mz-button mini">
      <mzic-spinner />
      Spinner
    </button>
  </div>

  <div>
    <button class="mz-button">
      Button
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button medium">
      Medium
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button small">
      Small
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button mini">
      Mini
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button only-icon">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button only-icon medium">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button only-icon small">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button class="mz-button only-icon mini">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button disabled class="mz-button only-icon">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button disabled class="mz-button only-icon medium">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button disabled class="mz-button only-icon small">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
    <button disabled class="mz-button only-icon mini">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button only-icon">
      <mzic-spinner />
    </button>
    <button class="mz-button only-icon medium">
      <mzic-spinner />
    </button>
    <button class="mz-button only-icon small">
      <mzic-spinner />
    </button>
    <button class="mz-button only-icon mini">
      <mzic-spinner />
    </button>
    <button disabled class="mz-button only-icon">
      <mzic-spinner />
    </button>
    <button disabled class="mz-button only-icon medium">
      <mzic-spinner />
    </button>
    <button disabled class="mz-button only-icon small">
      <mzic-spinner />
    </button>
    <button disabled class="mz-button only-icon mini">
      <mzic-spinner />
    </button>
  </div>

  <div>
    <button class="mz-button secondary">Secondary</button>
    <button class="mz-button secondary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Secondary
    </button>
    <button class="mz-button only-icon secondary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button tertiary">Tertiary</button>
    <button class="mz-button tertiary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Tertiary
    </button>
    <button class="mz-button only-icon tertiary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button outline">Outline</button>
    <button class="mz-button outline">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Outline
    </button>
    <button class="mz-button only-icon outline">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button outline white">Outline White</button>
    <button class="mz-button outline white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Outline White
    </button>
    <button class="mz-button only-icon outline white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button outline weak">Outline Weak</button>
    <button class="mz-button outline weak small">Outline Weak</button>
    <button class="mz-button outline weak">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Outline weak
    </button>
    <button class="mz-button only-icon outline weak">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button ghost white">Ghost White</button>
    <button class="mz-button ghost white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Ghost White
    </button>
    <button class="mz-button only-icon ghost white">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div>
    <button class="mz-button ghost">Ghost</button>
    <button class="mz-button ghost">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Ghost
    </button>
    <button class="mz-button only-icon ghost">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div style="padding-left: 24px; gap: 48px">
    <button class="mz-button link">Button Link</button>
    <button class="mz-button link">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button Link
    </button>
    <button class="mz-button only-icon link">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
    </button>
  </div>

  <div style="padding-left: 24px; gap: 48px">
    <button class="mz-button link">Button Link</button>
    <button class="mz-button link">
      <mzic-spinner />
      Button Link
    </button>
    <button class="mz-button only-icon link">
      <mzic-spinner />
    </button>
  </div>

  <div>
    <button class="mz-button danger">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button class="mz-button medium danger">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button class="mz-button small danger">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button class="mz-button mini danger">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>

  <div>
    <button class="mz-button success">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button class="mz-button medium success">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button class="mz-button small success">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button class="mz-button mini success">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>

  <div>
    <button class="mz-button full">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button class="mz-button full medium secondary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button class="mz-button full small tertiary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button class="mz-button full mini outline">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>
  <div>
    <button class="mz-button full">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Button
    </button>
    <button class="mz-button full medium secondary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Medium
    </button>
    <button class="mz-button full small tertiary">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Small
    </button>
    <button class="mz-button full mini outline">
      <mzic-svg src="assets/icons/mzic-plus.svg" />
      Mini
    </button>
  </div>

  <div>
    <button class="mz-button medium secondary">
      <mzic-svg src="assets/icons/mzic-filter.svg" />
      FILTER
      <span class="neutral-400">0</span>
    </button>
  </div>

  <div>
    <button class="mz-button-tag">
      <span class="label">All</span>
      <span class="number">32</span>
    </button>
    <button class="mz-button-tag active">
      <span class="label">Never distributed</span>
      <span class="number">32</span>
    </button>
    <button class="mz-button-tag">
      <span class="label">Processing</span>
      <span class="number">0</span>
    </button>
    <button class="mz-button-tag">
      <span class="label">Distributed</span>
      <span class="number">0</span>
    </button>
    <button class="mz-button-tag">
      <span class="label">Issues</span>
      <span class="number">0</span>
    </button>
    <button class="mz-button-tag">
      <span class="label">Taken down</span>
      <span class="number">0</span>
    </button>
  </div>
</div>
