export interface ArtistSocialMediaDataModel {
  uuid: string;
  name: string;
  image: string;
  monthlyListeners: number;
  followers: number;
  instagramUsername: string;
  instagramPhoto: string;
  instagramPosts: number;
  instagramFollowers: number;
  instagramFollowings: number;
}
