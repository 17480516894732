import { AssetsAvailable } from './assets-available.interface';
import { PayorWallet } from './royalty-split-list.interface';
import { RoyaltySplitTeam } from './royalty-split-payee-team.interface';
import { RoyaltySplitPayee } from './royalty-split-payee.interface';
import { RoyaltySplitRules } from './royalty-split-rules.interface';

export interface RoyaltySplitInvitationPending {
  id: number;
  name: string;
  payorWallet: PayorWallet;
  revenueType: string;
  externalFunding: boolean;
  startDate: string;
  expirationDate?: string;
  type: string;
  assets: AssetsAvailable[];
  rules: RoyaltySplitRules[];
  payees: RoyaltySplitPayee[];
  teamSplit: RoyaltySplitTeam;
  version: number;
  code: string;
  status: string;
}
