import { Component, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'mzic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'backoffice';

  constructor(
    private _router: Router,
    private _renderer: Renderer2,
  ) {
    this._router.events.subscribe((events) => {
      if (events instanceof NavigationEnd) {
        if (events.url === '/' || events.url === '/home') {
          this._renderer.setStyle(document.body, 'background-color', '#ededed');
        } else {
          this._renderer.setStyle(document.body, 'background-color', '#111010');
        }
        if (events.url === '/') {
          this._router.navigate(['home']);
        }
      }
    });
  }
}
