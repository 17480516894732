import { ApiResponse } from '../shared/api-response.interface';

export interface GetReleaseV2Find extends ApiResponse {
  data: GetReleaseV2FindData;
}

export interface GetReleaseV2FindParams {
  page: number;
  size: number;
  // sort?: string;
  sort?: string;
  search?: string;
  labelIds?: number[];
  dspStatus?: string;
  releaseDateStart?: string;
  releaseDateEnd?: string;
  creationDateStart?: string;
  creationDateEnd?: string;
  releaseType?: string;
}

export interface GetReleaseV2FindData {
  totalPages: number;
  totalElements: number;
  size: number;
  content: GetReleaseV2FindItem[];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  pageable: {
    offset: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  first: boolean;
  last: boolean;
  empty: boolean;
}

export interface GetReleaseV2FindItem {
  id: number;
  metadataLanguageId: number;
  hash: string;
  title: string;
  titleVersion: string;
  label: {
    id: number;
    imageId: string;
    name: string;
    description: string;
  };
  upc: string;
  releaseDate: string;
  artistNames: string;
  releaseType: string;
  dspStatus: {
    name: string;
    code: string;
  };
  status: string;
}
