import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@jsverse/transloco';
import { LanguageService, SettingsService } from '@mzic/mzic-services';
import { LanguageEnum } from '@mzic/mzic-utils';

@Component({
  selector: 'mzic-private',
  templateUrl: 'private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent implements OnInit {
  private settingsService = inject(SettingsService);
  private languageService = inject(LanguageService);
  private transloco = inject(TranslocoService);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.settingsService
      .getSettingsV2Find()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          const userLang =
            data?.language === LanguageEnum.PORTUGUESE ? 'pt-br' : 'en';
          this.transloco.setActiveLang(userLang);
        },
        error: (error) => {
          console.error(
            'ERROR during getSettingsV2Find - using browserlang',
            error,
          );
          const browserLang = this.languageService.languageState();
          this.transloco.setActiveLang(browserLang);
        },
      });
  }
}
