import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const TeamSelectionGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  if (
    !localStorage.getItem('mzic-wallet-team') ||
    !localStorage.getItem('mzic-teamId-local')
  ) {
    router.navigate(['/team-selection']);
    return false;
  }
  return true;
};
