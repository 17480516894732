export interface GetMusicV2MusicId {
  id: number;
  name: string;
  description: string;
  genre: string;
  explicitContent: boolean;
  price: number;
  lyrics: string;
  subGenre: string;
  rarity: string;
  file: string;
  streamFile: string;
  video: string;
  editionNumber: number;
  remainingEditionNumber: number;
  releaseDate: Date;
  mintDuration: Date;
  commentary: string;
  coverFile: string;
  artistContractFile: string;
  artistMusic: [
    {
      artistNames: string[];
      artistType: {
        name: string;
        description: string;
      };
    },
  ];
  artistId: number;
  artistName: string;
  artistProfilePhoto: string;
  duration: number;
  hash: string;
  copyright: {
    registrationBureau: string;
    registrationBureauUrl: string;
    registrationNumber: string;
    registrationNumberUrl: string;
    copyrightType: string;
    isrc: string;
    songIswc: string;
    registrationDate: Date;
    percentOfCopyrightOnOffer: number;
    networkId: string;
    network: string;
    copyrightContractAddress: string;
    copyrightContractAddressUrl: string;
    tokenId: number;
    tokenIdUrl: string;
    shareContract: string;
    shareContractUrl: string;
    tokenType: string;
    urlLegalStatus: string;
    statusDspDistribution: string;
    statusApplication: string;
    networkIcon: string;
    expirationDate: Date;
    creatorEarnings: string;
    chainIcon: string;
  };
  analytics: {
    items: number;
    owners: number;
    floorPrice: number;
    tradedTotal: number;
    tradedSecondaryMarket: number;
    tradedPrimaryMarket: number;
  };
  urlHash: string;
  isrc: string;
  iswc: string;
  iswcUrl: string;
  registrationBureau: string;
  registrationDate: Date;
  sharesCount: number;
}
