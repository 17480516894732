import { ReleaseStatusEnum, ReleaseTypeEnum } from '../releases';
import { SelectOption } from '../select/select-option.interface';
import {
  ArtistItem,
  Genre,
  LabelResponse,
  Language,
  TrackArtist,
  TrackLanguage,
  TrackResourceForm,
  TrackSimpleReadResponse,
} from './track-resource.interfaces';

export interface ReleaseState {
  languageOptions: SelectOption[];
  languageDataSelected: SelectOption[];
  release?: Partial<ReleaseReadResponse>;
  tracksSelected: TrackSimpleReadResponse[];
  incompleteTracksSelected: Partial<TrackSimpleReadResponse>[];
  form?: Partial<ReleaseResourceForm>;
}

export interface ReleaseReadResponse {
  id: number;
  language: Language;
  hash: string;
  title: string;
  titleVersion: string;
  primaryGenre: Genre;
  secondaryGenre: Genre;
  label: LabelResponse;
  previouslyReleased: boolean;
  releaseDate: string;
  releaseExternalId: number | null;
  totalDuration: number;
  artistNames: string;
  status: ReleaseStatusEnum;
  artists: ArtistItem[];
  releaseLanguages: ReleaseLanguage[];
  tracks: TrackSimpleReadResponse[];
}

/**
 * Interface necessária para PUT da informações da track
 */
export interface ReleaseResourceForm {
  id?: number;
  metadataLanguageId: number;
  trackIds: number[];
  hash: string;
  title: string;
  titleVersion: string;
  primaryGenreId: number;
  secondaryGenreId: number;
  labelId: number;
  previouslyReleased: boolean;
  upc?: string;
  releaseDate?: string;
  artists: TrackArtist[];
  releaseLanguages: TrackLanguage[];
}

export interface ReleaseBulkResourceForm
  extends Omit<ReleaseResourceForm, 'trackIds'> {
  tracks: Partial<TrackResourceForm>[];
}

export interface ReleaseLanguage {
  title: string;
  language: Language;
}

export interface ReleaseItem {
  id: number;
  language: Language;
  title: string;
  titleVersion: string;
  artistNames: string;
  hash?: string;
  upc?: string;
  releaseDate?: string;
  status?: string;
}

export interface ReleaseResourceData {
  id: number;
  language: Language;
  hash: string;
  title: string;
  titleVersion: string;
  primaryGenre: Genre;
  secondaryGenre: Genre;
  label: LabelResponse;
  previouslyReleased: boolean;
  upc: string;
  releaseDate: string;
  releaseType: ReleaseTypeEnum;
  releaseExternalId: number;
  totalDuration: number;
  artistNames: string;
  status: ReleaseStatusType;
  artists: ArtistItem[];
  releaseLanguages: ReleaseLanguage[];
  tracks: TrackSimpleReadResponse[];
  hasReleased: boolean;
}

export type ReleaseStatusType =
  | 'READY'
  | 'IN_INSPECTION'
  | 'REJECTED_INSPECTION'
  | 'DISTRIBUTED'
  | 'TAKEN_DOWN';

export interface ReleaseError {
  error: string;
  title?: string;
  code: string;
  trace?: string;
  metadata?: string;
  modal: boolean;
}
