import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  SelectOptionData,
  SelectOrientationEnum,
  SelectVariationEnum,
} from '@mzic/mzic-interfaces';
import { MzicSvgComponent } from '../mzic-svg/mzic-svg.component';
import { SelectSearchComponent } from '../select-search/select-search.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { BoldCurrencyPipe } from './pipes/bold-currency/bold-currency.pipe';
import { ExtractTitlePipe } from './pipes/extract-title/extract-title.pipe';

@Component({
  selector: 'mzic-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  imports: [
    CommonModule,
    MzicSvgComponent,
    SelectSearchComponent,
    BoldCurrencyPipe,
    ExtractTitlePipe,
    SpinnerComponent,
  ],
})
export class SelectComponent implements OnChanges, OnDestroy {
  @Input() variation: SelectVariationEnum = SelectVariationEnum.Default;
  @Input() orientation: SelectOrientationEnum | undefined;
  @Input() optionsSelected: SelectOptionData[] | undefined;
  @Input() optionsLabel: string | undefined;
  @Input() placeholder = '';
  @Input() useLightStyle = false;
  @Input() useSuccessStyle = false;
  @Input() useErrorStyle = false;
  @Input() disabled = false;
  @Input() editabble = true;
  @Input() searchable = false;
  @Input() extraPadding = false;
  @Input() showSearchLoading = false;
  @Input() invalid = false;
  @Input() multiple = false;
  @Input() fixedContent = false;
  @Input() loading = false;

  @Output() searchTextChange = new EventEmitter<string>();
  @Output() optionChange = new EventEmitter<(string | number)[] | undefined>();
  @Output() closedChange = new EventEmitter<boolean>();

  get hasOptionsSelected() {
    return this.optionsSelected && this.optionsSelected?.length > 0;
  }

  get optionSelected() {
    const [option] = this.optionsSelected ?? [];
    return option;
  }

  // Controla a exibição dos resultados
  opened = false;

  // Garante que ao clicar no botão de limpar a pesquisa, o conteúdo se mantenha aberto
  searchCleaned = false;

  intersectionObserver: IntersectionObserver | undefined;

  selectVariationEnum = SelectVariationEnum;
  selectOrientationEnum = SelectOrientationEnum;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const clickedInside = (event.target as HTMLElement).closest('.select');
    if (!clickedInside && this.opened && !this.searchCleaned) {
      this.toggleContent();
    }

    if (this.searchCleaned) {
      this.searchCleaned = false;
    }
  }

  constructor(public elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
  }

  listenIntersectionObserver() {
    // Utiliza API Intersection Observer API mudar a orientação do dropdown do select
    const options = {
      root: null, // A janela inteira será usada como área de observação
      rootMargin: '0px',
      threshold: 1.0,
    };

    this.intersectionObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          // Muda orientação
          if (!entry.isIntersecting && entry.boundingClientRect.top > 180) {
            this.orientation = SelectOrientationEnum.Top;
          }

          if (!entry.isIntersecting && entry.boundingClientRect.top <= 0) {
            this.orientation = SelectOrientationEnum.Bottom;
          }
        });
      },
      options,
    );
    const target: HTMLDivElement =
      this.elementRef.nativeElement.querySelector('.observer');
    if (target) {
      this.intersectionObserver.observe(target);
    }
  }

  toggleContent() {
    if (this.loading) {
      return;
    }

    if (!this.disabled && this.editabble) {
      this.opened = this.opened ? false : true;
    }

    // Alguns casos, principalmente em modais, o conteúdo precisa ser fixed
    if (this.opened && this.fixedContent) {
      setTimeout(() => {
        const element =
          document.querySelector<HTMLDivElement>('.select__content');
        if (element) {
          element.style.position = 'fixed';
          element.style.width = '524px'; // uma melhoria seria pegar esse valor dinamicamente do elemento pai
        }
      }, 50);
    }

    if (this.opened && !this.orientation) {
      setTimeout(() => {
        this.listenIntersectionObserver();
      });
    } else {
      this.intersectionObserver?.disconnect();
      if (!this.opened) {
        this.closedChange.emit(true);
      }
    }
  }

  handleClearTextChange(cleaned: boolean) {
    this.searchCleaned = cleaned;
  }

  handleSearchTextChange(event: string) {
    this.searchTextChange.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.loading) {
      return;
    }

    if (!changes['optionsSelected']?.firstChange && !this.multiple) {
      // Casos especiais que ele bloqueia a exibição dos itens
      if (!changes['optionsSelected']) {
        if (
          changes['orientation'] ||
          changes['disabled'] ||
          changes['useSuccessStyle'] ||
          changes['useErrorStyle'] ||
          changes['extraPadding'] ||
          changes['invalid']?.currentValue
        ) {
          return;
        }
      }

      // Caso especial quando o valor é atribuido após o carregamento por API, nesse caso ele deve ignorar abrir o select, e abrir somente quando o usuário realmente clicar
      if (
        changes['optionsSelected'] &&
        !changes['optionsSelected'].previousValue
      ) {
        this.opened = false;
        return;
      }

      this.toggleContent();
    }
  }
}
