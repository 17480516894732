import { RoyaltySplitPayee } from './royalty-split-payee.interface';
import { RoyaltySplitPriorityPayee } from './royalty-split-priority-payee.interface';

export interface RoyaltySplit {
  name: string;
  revenueType: string;
  expirationDate: string;
  externalFunding: boolean;
  payees: RoyaltySplitPayee[];
  priorityPayees: RoyaltySplitPriorityPayee[];
  dspsToInclude: number[];
  dspsToExclude: number[];
  countriesToInclude: number[];
  countriesToExclude: number[];
  releaseIds: number[];
  trackIds: number[];
}
