export interface MusicStylesList {
  timestamp: Date;
  status: string;
  data: MusicStyleItem[];
  error: {
    error: string;
    title: string;
    code: string;
    trace: string;
    modal: boolean;
  };
  page: number;
  total_pages: number;
  per_page: number;
  total_items: number;
}

export interface MusicStyleItem {
  musicStyleId: number;
  name: string;
  parentId: number;
  order: number;
}
