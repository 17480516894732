import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import {
  MzicArtistLocalService,
  MzicTokenLocalService,
} from '@mzic/mzic-services';

export const MzicLoggedInUserMatch: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
) => {
  const _tokenService = inject(MzicTokenLocalService);
  const _userLocal = inject(MzicArtistLocalService);
  const _router = inject(Router);
  const existsToken = _tokenService.getTokenLocal();
  console.log(existsToken);
  if (!existsToken) {
    return true;
  } else {
    const artistId = _userLocal.getTeamId();
    _router.navigate([`dashboard/${artistId}/songs`]);
    return false;
  }
};
