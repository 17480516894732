export interface Store {
  id: number;
  iconUrl: string;
  name: string;
  active: boolean;
  tierPriceSale: TierPriceSale;
  lsMonetizationPolicy: LsMonetizationPolicy[];
  dependencyStore: DependencyStore;
  lsToolTip: LsToolTip[];
}

export interface TierPriceSale {
  lsTierPriceRelease: LsTierPriceRelease[];
  lsTierPriceTrack: LsTierPriceRelease[];
}

export interface LsTierPriceRelease {
  id: number;
  value: number;
  name: string;
}

export interface LsMonetizationPolicy {
  id: number;
  name: string;
}

export interface DependencyStore {
  id: number;
  tierPriceReleaseId: number;
  tierPriceTrackId: number;
  monetizationPolicyId: number;
}

export interface LsToolTip {
  type: string;
  title: string;
  text: string;
}
