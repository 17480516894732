<h1>Tables</h1>
<div class="components-container">
  <div>
    <div class="mzic-table min-height">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div>
                <label>Title</label>
                <mzic-tooltip />
                <mzic-table-sort id="title" />
              </div>
            </th>
            <th>
              <div>
                <label>ISRC</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div>
                <label>Main genre</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div></div>
            </th>
          </tr>
        </thead>
      </table>
      <div class="empty-container">
        <span class="mzic-text-body-xxsm">This table is empty</span>
        <button class="mz-button medium white">
          <mzic-svg src="assets/icons/mzic-plus.svg" />
          CREATE A NEW RELEASE
        </button>
      </div>
      <footer>
        <div>
          <mzic-table-pagination />
        </div>
        <div>
          <mzic-table-page-size />
          <div class="items-total">
            of
            <span>30</span>
            items
          </div>
        </div>
      </footer>
    </div>

    <div class="mzic-table">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div>
                <label>Title</label>
                <mzic-tooltip />
                <mzic-table-sort id="title" />
              </div>
            </th>
            <th>
              <div>
                <label>ISRC</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div>
                <label>Main genre</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div></div>
            </th>
          </tr>
        </thead>
      </table>
      <div class="empty-container">
        <span class="mzic-text-body-xxsm">
          We couldn't findy any results matching your search
        </span>
      </div>
    </div>

    <div class="mzic-table min-height">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div>
                <label>Title</label>
                <mzic-tooltip />
                <mzic-table-sort id="title" />
              </div>
            </th>
            <th>
              <div>
                <label>ISRC</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div>
                <label>Main genre</label>
                <mzic-tooltip />
              </div>
            </th>
            <th class="mzic-table-column-actions-button">
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of [1, 2, 3, 4, 5]">
            <td class="mzic-table-column-check">
              <mzic-skeleton />
            </td>
            <td><mzic-skeleton /></td>
            <td><mzic-skeleton /></td>
            <td><mzic-skeleton /></td>
            <td class="mzic-table-column-actions-button"></td>
          </tr>
        </tbody>
      </table>
      <footer>
        <div>
          <mzic-table-pagination />
        </div>
        <div>
          <mzic-table-page-size />
          <div class="items-total">
            of
            <span>30</span>
            items
          </div>
        </div>
      </footer>
    </div>

    <div class="mzic-table min-height">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div>
                <label>Title</label>
                <mzic-tooltip />
                <mzic-table-sort id="title" />
              </div>
            </th>
            <th>
              <div>
                <label>ISRC</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div>
                <label>Main genre</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="hoverable">
            <td class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </td>
            <td>LET'S GET FKD UP</td>
            <td>2997198213</td>
            <td>Eletronic/Pop</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <footer>
        <div>
          <mzic-table-pagination />
        </div>
        <div>
          <mzic-table-page-size />
          <div class="items-total">
            of
            <span>30</span>
            items
          </div>
        </div>
      </footer>
    </div>
    <div class="mzic-table min-height medium">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div>
                <label>Title</label>
                <mzic-tooltip />
                <mzic-table-sort id="title" />
              </div>
            </th>
            <th>
              <div>
                <label>ISRC</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div>
                <label>Main genre</label>
                <mzic-tooltip />
              </div>
            </th>
            <th>
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="hoverable" *ngFor="let item of [1, 2, 3, 4, 5]">
            <td class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </td>
            <td>LET'S GET FKD UP</td>
            <td>2997198213</td>
            <td>Eletronic/Pop</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <footer>
        <div>
          <mzic-table-pagination />
        </div>
        <div>
          <mzic-table-page-size />
          <div class="items-total">
            of
            <span>30</span>
            items
          </div>
        </div>
      </footer>
    </div>

    <div class="mzic-table medium">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-sort">
              <mzic-svg
                src="assets/icons/mzic-move.svg"
                class="w12 neutral-300" />
            </th>
            <th class="mzic-table-column-play"></th>
            <th>
              <div><label>Title</label></div>
            </th>
            <th>
              <div><label>ISRC</label></div>
            </th>
            <th>
              <div><label>Duration</label></div>
            </th>
            <th></th>
            <th class="mzic-table-column-bullets"></th>
          </tr>
        </thead>
        <tbody
          [sortablejs]="items1"
          [sortablejsOptions]="{ disabled: disableSort }">
          <tr *ngFor="let item of items1; index as i" class="hoverable">
            <td
              class="mzic-table-column-sort"
              (mouseenter)="disableSort = false"
              (mouseleave)="disableSort = true">
              <mzic-svg src="assets/icons/mzic-move.svg" class="w12" />
            </td>
            <td class="mzic-table-column-play">
              <div class="sort-number" *ngIf="!disableSort">{{ i + 1 }}</div>
              <mzic-svg
                *ngIf="!item.isPlaying && disableSort"
                src="assets/icons/mzic-play.svg"
                class="purple-500 w24" />
              <mzic-svg
                *ngIf="item.isPlaying && disableSort"
                src="assets/icons/mzic-pause.svg"
                class="purple-500 w24" />
            </td>
            <td>
              <mzic-track-list-item
                [title]="item.title"
                [description]="item.description"
                [image]="item.image" />
            </td>
            <td>{{ item.isrc }}</td>
            <td>{{ item.duration | mzicDuration }}</td>
            <td>
              <a href="#" class="track-status-tag {{ item.tagClass }}">
                <mzic-svg
                  [src]="item.tagIcon"
                  *ngIf="item.tagIcon.length > 0"
                  class="w12 {{ item.tagClass }}" />
                <span>{{ item.tagText }}</span>
              </a>
            </td>
            <td class="mzic-table-column-bullets">
              <button class="mz-button ghost only-icon mini white">
                <mzic-svg src="assets/icons/mzic-bullets.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mzic-table small">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div><label>Service</label></div>
            </th>
            <th class="mzic-table-column-small"></th>
            <th class="mzic-table-column-dsp-status">
              <div><label>Status</label></div>
            </th>
            <th class="mzic-table-column-bullets"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let service of services; index as i" class="hoverable">
            <td class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </td>
            <td class="mzic-table-column-dsp-service">
              <img [src]="service.image" />
              {{ service.name }}
            </td>
            <th class="mzic-table-column-small">
              <mzic-svg
                [src]="service.questionIcon"
                *ngIf="service?.questionIcon?.length"
                class="w18" />
            </th>
            <td class="mzic-table-column-dsp-status">
              <mzic-svg
                [src]="service.statusIcon"
                class="{{ service.statusColor }}" />
            </td>
            <td class="mzic-table-column-bullets">
              <button class="mz-button ghost only-icon mini white">
                <mzic-svg src="assets/icons/mzic-bullets.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mzic-table small">
      <table>
        <thead>
          <tr>
            <th class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </th>
            <th>
              <div><label>Service</label></div>
            </th>
            <th>
              <div><label>Enqueued on</label></div>
            </th>
            <th>
              <div><label>Delivered on</label></div>
            </th>
            <th class="mzic-table-column-small"></th>
            <th class="mzic-table-column-dsp-status">
              <div><label>Status</label></div>
            </th>
            <th class="mzic-table-column-bullets"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let service of services; index as i" class="hoverable">
            <td class="mzic-table-column-check">
              <input type="checkbox" mzicCheckbox />
            </td>
            <td class="mzic-table-column-dsp-service">
              <img [src]="service.image" />
              {{ service.name }}
            </td>
            <td>
              <div><label>-</label></div>
            </td>
            <td>
              <div><label>-</label></div>
            </td>
            <th class="mzic-table-column-small">
              <mzic-svg
                [src]="service.questionIcon"
                *ngIf="service?.questionIcon?.length"
                class="w18" />
            </th>
            <td class="mzic-table-column-dsp-status">
              <mzic-svg
                [src]="service.statusIcon"
                class="{{ service.statusColor }}" />
            </td>
            <td class="mzic-table-column-bullets">
              <button class="mz-button ghost only-icon mini white">
                <mzic-svg src="assets/icons/mzic-bullets.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mzic-table small">
      <table>
        <thead>
          <tr>
            <th>
              <div><label>Service</label></div>
            </th>
            <th>
              <div><label>Album retail tier price</label></div>
            </th>
            <th>
              <div><label>Track retail tier price</label></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let service of [services[0], services[1]]; index as i"
            class="hoverable">
            <td class="mzic-table-column-dsp-service">
              <img [src]="service.image" />
              {{ service.name }}
            </td>
            <td>
              <div>
                <label>
                  <div class="mz-form-row for-select auto small">
                    <div class="mz-input">
                      <select>
                        <option value="">Default</option>
                      </select>
                      <mzic-tooltip />
                    </div>
                  </div>
                </label>
              </div>
            </td>
            <td>
              <div>
                <label>
                  <div class="mz-form-row for-select auto small">
                    <div class="mz-input">
                      <select>
                        <option value="">Default</option>
                      </select>
                      <mzic-tooltip />
                    </div>
                  </div>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
